import React from 'react';

import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CardMedia from '@mui/material/CardMedia';
import ListItemText from '@mui/material/ListItemText';

import { OpenInNew } from '@mui/icons-material';

import type { ResourcesListItemProps } from './types';

export function ResourcesListItem({
  image,
  title,
  link,
  resourceName
}: Readonly<ResourcesListItemProps>) {
  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="openInNew">
          <Link href={link} target="_blank" rel="noopener" color="inherit">
            <OpenInNew />
          </Link>
        </IconButton>
      }
    >
      <ListItemAvatar>
        <Avatar>
          <CardMedia component="img" image={image} alt={title || 'image'} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={title} secondary={resourceName} />
    </ListItem>
  );
}
