/*
 * Copyright 2021 Spotify AB
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useState, useEffect } from 'react';

import {
  githubAuthApiRef,
  ProfileInfo,
  useApi,
} from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';

import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';

import { GithubUserCard } from '../GithubUserCard';

const useStyles = makeStyles(theme => {
  return {
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    hidden: {
      visibility: 'hidden',
      display: 'none',
    },
  };
});

export const GBTechGetGithubToken = ({
  onChange,
  schema: {
    title = 'Github Token',
    description = 'This user will be used to perform Github operations',
  },
  required,
  uiSchema,
  rawErrors,
  formData,
}: FieldExtensionComponentProps<string>) => {
  const options = 'ui:options';
  const scopes = uiSchema[options]?.scopes as string[];
  const classes = useStyles();
  const githubAuth = useApi(githubAuthApiRef);
  const [githubAccessToken, setGithubAccessToken] = useState(String);
  const [githubUserInfo, setGithubUserInfo] = useState<ProfileInfo>();

  useEffect(() => {
    const fetchGithubUserInfo = async () => {
      const requiredScopes = scopes && scopes.length > 0 ? scopes : ['user'];
      const accessToken = await githubAuth.getAccessToken(requiredScopes);
      const userInfo = await githubAuth.getProfile();

      setGithubAccessToken(accessToken);
      setGithubUserInfo(userInfo);
      onChange(accessToken);
    };

    if (!githubAccessToken || !githubUserInfo) {
      fetchGithubUserInfo();
    }
  });

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <GithubUserCard
        description={description}
        title={title}
        githubUserInfo={githubUserInfo}
      />
      <TextField
        value={githubAccessToken}
        margin="normal"
        variant="outlined"
        required={required}
        className={classes.hidden}
        type="password"
        InputProps={{
          readOnly: true,
        }}
      />
    </FormControl>
  );
};

export const GBTechGetGithubTokenFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechGetGithubToken,
    name: 'GBTechGetGithubToken',
  }),
);
