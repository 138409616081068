import React from 'react';

import { EntitySwitch } from '@backstage/plugin-catalog';
import { SecurityFrontendPage } from '@grupoboticario/backstage-plugin-maturity-security-frontend';

export const SecurityContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={(e) => e.kind === 'Component'}>
      <SecurityFrontendPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
