import React from 'react';

import { useStyles } from './styles';
import { RenderModal } from '../../RenderModal';
import { ChecksCard } from '../../ChecksCard';

export const CatalogIntegrity = () => {
  const S = useStyles();
  return (
    <div className={S.contDetails}>
      <p className={S.titleProperty}>CATALOG INTEGRITY</p>
      <RenderModal titleLink="show integrity">
        <ChecksCard />
      </RenderModal>
    </div>
  );
};
