import React, { Fragment } from 'react';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';

import { useEntity } from '@backstage/plugin-catalog-react';

import { useStyles } from './styles';
import { EntityMeta } from './types';

export const ChecksCard = () => {
  const S = useStyles();
  const { entity } = useEntity();

  const catalogInfoUrl = entity.metadata.annotations?.['backstage.io/managed-by-location'] ?? '';
  const alquimiaChecks = (entity.metadata as EntityMeta).alquimia?.checks ?? undefined;
  const regex = /github\.com\/([^/]+)\/([^/]+)/;
  const match = catalogInfoUrl.match(regex);
  const org = match ? match[1] : '';
  const repo = match ? match[2] : '';

  return (
    <>
      <Card data-testid="checks-card">
        <CardHeader
          title="Integridade de catálogo"
          subheader="Validação executada na default branch"
        />
        <Divider className={S.CardDivider} />
        <CardContent className={S.CardContent}>
          {!alquimiaChecks && (
            <p> O Alquimia não pôde realizar a validação. Tente novamente mais tarde. </p>
          )}
          {!alquimiaChecks?.ok && alquimiaChecks?.errors && (
            <>
              {Object.keys(alquimiaChecks.errors).map((filename) => (
                <Fragment key={String(filename)}>
                  <Typography>
                    Erros encontrados no arquivo{' '}
                    <Link
                      className={S.LinkFile}
                      href={`https://github.com/${org}/${repo}/blob/main/${filename}`}
                    >
                      {filename}
                    </Link>
                    :
                  </Typography>
                  <div className={S.ErrorsList}>
                    {alquimiaChecks?.errors &&
                      Object.keys(alquimiaChecks.errors[filename]).map((component: string) => (
                        <Paper elevation={3} className={S.ComponentWrapper} key={component}>
                          <span className={S.SpanComponent}>Componente: {component}</span>
                          <List className={S.ErrorListComponent}>
                            {alquimiaChecks?.errors &&
                              alquimiaChecks.errors[filename][component].map(
                                (error: string, index: number) => (
                                  <ListItem
                                    className={S.ErrorListItem}
                                    key={`${index}-${component}`}
                                  >
                                    <CancelIcon
                                      data-testid="CancelIcon"
                                      className={S.CustomIcons}
                                      color="error"
                                      fontSize="small"
                                    />
                                    {error}
                                  </ListItem>
                                )
                              )}
                          </List>
                        </Paper>
                      ))}
                  </div>
                </Fragment>
              ))}
            </>
          )}
          {alquimiaChecks?.ok && (
            <Typography className={S.SuccessWrapper}>
              <CheckCircleRoundedIcon className={S.CustomIcons} color="success" fontSize="small" />{' '}
              Parabéns! O componente está íntegro!
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
};
