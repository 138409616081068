import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  Wrapper: {
    width: '100%',
    maxWidth: 1250,
    margin: '0 auto',
    marginTop: theme.spacing(2),
  },
  Title: {
    textAlign: 'start',
    width: '100%',
  },
  ChartInfoWrapper: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'space-around',
  },
  ChartInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '0.8rem',
    fontWeight: 500,
    margin: '0 !important',
    '& > span': {
      fontWeight: 'bold',
      fontSize: '1.2rem',
    },
  },
  Link: {
    width: '100%',
    maxWidth: 1250,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: 1,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
    '& > svg': {
      fontSize: 14,
    },
  },
}));
