import React from 'react';

import * as T from './types';
import { styled } from '@mui/material/styles';
const Text = styled('text', { name: 'text' })(({ theme }) => ({
  fill: theme.palette.text.primary,
  fontSize: 12,
  fontWeight: 'bold',
  textAnchor: 'middle'
}));
const labelOffset = 10;
export default function BarTotalsLayer(props: Readonly<T.BarTotalsLayerProps>) {
  if (props.bars.length === 0) return null;

  const totals: { [key: string]: number } = {};
  const bandwidth = props.bars[0].width;

  props.bars.forEach((bar) => {
    const indexValue = bar.data.indexValue;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });

  const labels = Object.keys(totals).map((indexValue) => {
    const x = props.xScale(indexValue) + bandwidth / 2;
    const y = props.yScale(totals[indexValue]) - labelOffset;
    return (
      <Text key={'total.' + indexValue} x={x} y={y}>
        ${totals[indexValue].toFixed(2)}
      </Text>
    );
  });

  return <>{labels}</>;
}
