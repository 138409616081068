import { createThemeConfig, getTheme } from './configTheme';
import { Theme } from '@mui/material/styles';

const theme = getTheme(window.location.hostname);

declare module '@mui/styles/defaultTheme' {
  //eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface DefaultTheme extends Theme {}
}

export const themeConfig = createThemeConfig(theme);
