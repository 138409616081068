import React from 'react';

import { useStyles } from './styles';
import type { DescriptionProps } from './types';

export const Description = ({ description }: DescriptionProps) => {
  const S = useStyles();
  return (
    <div className={S.contDetails}>
      <p className={S.titleProperty}>DESCRIPTION</p>
      <p className={S.resultProperty}>{description}</p>
    </div>
  );
};
