import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  contEnv: {
    padding: '20px',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: '0px 0px 10px 0px',
    fontSize: '21px',
  },
}));
