/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';

import Grid from '@mui/material/Grid';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  LayoutTemplate,
  createScaffolderLayout,
} from '@backstage/plugin-scaffolder-react';

import CostsSummary from '../../CostsSummary';

const CostsSummaryLayoutTemplate: LayoutTemplate = ({
  properties,
  description,
  title,
  formData,
}) => {
  return (
    <>
      <h1>{title}</h1>
      <Grid container justifyContent="flex-end">
        {properties.map(prop => (
          <Grid item xs={8} key={prop.content.key}>
            {prop.content}
          </Grid>
        ))}
        <Grid item xs={4}>
          <CostsSummary formData={formData} />
        </Grid>
      </Grid>
      {description}
    </>
  );
};

export const CostsSummaryLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'CostsSummary',
    component: CostsSummaryLayoutTemplate,
  }),
);
