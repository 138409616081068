import React from 'react';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import FileCopyIcon from '@mui/icons-material/FileCopy';

import { useStyles } from './styles';
import * as T from './types';

export const RenderKeyValue: React.FC<T.RenderKeyValueProps> = ({
  keyText,
  valueText,
  copy,
  truncateText,
  maxLength = 20,
}) => {
  const S = useStyles();

  const truncate = (input: string, maxLength: number) =>
    input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div className={S.keyValue}>
      <p className={S.key}>{keyText}</p>
      <div className={S.copybutton}>
        {truncateText ? (
          <Tooltip title={valueText} placement="top">
            <p className={S.value}>{truncate(valueText, maxLength)}</p>
          </Tooltip>
        ) : (
          <p className={S.value}>{valueText}</p>
        )}
        {copy && (
          <IconButton
            className={S.icon}
            onClick={() => copyToClipboard(valueText)}
            aria-label={`copiar ${keyText}`}
          >
            <FileCopyIcon className={S.icon} />
          </IconButton>
        )}
      </div>
    </div>
  );
};

export const RenderDetails = ({
  keyValues,
}: {
  keyValues: T.RenderDetailsProps[];
}) => {
  return keyValues.map(({ keyText, valueText, copy, truncateText }, index) => {
    if (
      valueText !== undefined &&
      valueText !== '' &&
      !String(valueText).includes('undefined')
    ) {
      return (
        <RenderKeyValue
          key={`${keyText}-${index}`}
          keyText={keyText}
          valueText={String(valueText)}
          copy={copy}
          truncateText={truncateText}
        />
      );
    }
    return null;
  });
};
