import React, { useEffect, useState } from 'react';

import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';

import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';

import DescriptionIcon from '@mui/icons-material/Description';

import { useStyles } from './styles';

export const DocumentationsCard = () => {
  const S = useStyles();
  const { entity } = useEntity();
  const catalogApi = useApi(catalogApiRef);
  const [result, setResult] = useState<string[] | null>(null);

  const getDocumentations = async (systemName: any) => {
    const request = {
      filter: {
        kind: 'Component',
        'spec.system': systemName,
      },
    };
    const response = await catalogApi.getEntities(request);
    const namesWithTechDocsRef = response.items
      .filter(
        entity =>
          entity.metadata.annotations &&
          entity.metadata.annotations['backstage.io/techdocs-ref'],
      )
      .map(entity => entity.metadata.name);
    return namesWithTechDocsRef;
  };

  useEffect(() => {
    getDocumentations(entity.metadata.name).then(result => {
      setResult(result);
    });
  }, []);
  const truncate = (input: string, maxLength: number) =>
    input.length > maxLength ? `${input.substring(0, maxLength)}...` : input;
  const maxLength = 20;

  return (
    <InfoCard title='Component Documentations' className={S.cont}>
      <List className={S.toolkit}>
      {result && result.length > 0 ? (
        result.map(item => (
          <Button
            key={item}
            variant="contained"
            color="primary"
            href={`/docs/default/Component/${item}`}
            className={S.tool}
          >
            <Tooltip title={item} placement="top">
              <div className={S.linkList}>
                <ListItemIcon className={S.icon}>
                  <DescriptionIcon className={S.iconSize} />
                </ListItemIcon>
                <p className={S.value}>{truncate(item, maxLength)}</p>
              </div>
            </Tooltip>
          </Button>
        ))
      ) : (
        <p>Não existem documentações de componentes cadastradas neste sistema.</p>
      )}
      </List>
    </InfoCard>
  );
};
