import React from 'react';

import { makeStyles } from '@mui/styles';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles(theme => {
  return {
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    hidden: {
      visibility: 'hidden',
      display: 'none',
    },
    gridItemDescription: {
      paddingLeft: `${theme.spacing(3)} !important`,
    },
  };
});

interface Props {
  githubUserInfo: any;
  description: string;
  title: string;
}

export const GithubUserCard = ({
  githubUserInfo,
  description,
  title,
}: Props) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant="body2" color="textSecondary">
          {title}
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item>
          <Avatar
            alt={githubUserInfo?.displayName}
            src={githubUserInfo?.picture}
            className={classes.large}
          />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs className={classes.gridItemDescription}>
              <Typography variant="subtitle1" gutterBottom>
                {githubUserInfo?.displayName}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {githubUserInfo?.email}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};
