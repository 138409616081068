import React, { useEffect, useState } from 'react';

import {
  configApiRef,
  discoveryApiRef,
  githubAuthApiRef,
  identityApiRef,
  useApi,
  ProfileInfo
} from '@backstage/core-plugin-api';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';

import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';

import axios from 'axios';

import { GithubUserCard } from '../GithubUserCard';
import { GithubIAMAPIClient } from '../common/github-iam/api';
import { useStyles } from './styles';

export const GBTechGetGithubUserInfo = ({
  onChange,
  schema: {
    title = 'Github User Info',
    description = 'This user will be used as committer in Github Operations'
  },
  required,
  rawErrors,
  formData
}: FieldExtensionComponentProps<ProfileInfo>) => {
  const S = useStyles();

  const githubAuth = useApi(githubAuthApiRef);
  const [githubUserProfile, setGithubUserProfile] = useState<ProfileInfo>();
  const [githubUserLogin, setGithubUserLogin] = useState<string>();
  const [noUserFoundError, setNoUserFoundError] = useState<boolean>(false);
  const [userUnauthorizedError, setUserUnauthorizedError] = useState<boolean>(false);
  const config = useApi(configApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);
  const gitHubUrl: string = config.get('github.apiUrl');
  const githubOrg: string = config.get('github.orgName');

  const getUserData = async (accessToken: string) => {
    const basicToken = Buffer.from(`:${accessToken}`, 'utf-8').toString('base64');
    const userData = await axios.get(`${gitHubUrl}/user`, {
      headers: { Authorization: `Basic ${basicToken}` }
    });
    return userData.data;
  };

  const createAlternativeEmail = async (userData: any) => {
    return `${userData.login}@users.noreply.github.com`;
  };

  useEffect(() => {
    const fetchGithubUserInfo = async () => {
      const userProfile = await githubAuth.getProfile();
      const accessToken = await githubAuth.getAccessToken();
      const userData = await getUserData(accessToken);
      const iamAPI = new GithubIAMAPIClient({ discoveryApi, identityApi });
      const iamUser = await iamAPI.getUserByGithubLogin(userData.login);
      setGithubUserLogin(userData.login);
      setNoUserFoundError(false);
      setUserUnauthorizedError(false);
      if (!iamUser) {
        setNoUserFoundError(true);
        onChange(undefined);
        return;
      }

      const hasOrgAccess = iamUser.orgs.some(
        (o) => o.github_org === githubOrg && o.is_active === true
      );
      const userActive = iamUser.user.is_active;
      if (!userActive || !hasOrgAccess) {
        setUserUnauthorizedError(true);
        onChange(undefined);
        return;
      }

      if (userProfile && !userProfile.email) {
        const alternativeEmail = await createAlternativeEmail(userData);
        userProfile.email = alternativeEmail;
      }
      setGithubUserProfile(userProfile);
      onChange(userProfile);
    };

    if (!githubUserProfile) {
      fetchGithubUserInfo();
    }
  }, []);

  return (
    <FormControl margin="normal" required={required} error={rawErrors?.length > 0 && !formData}>
      {!noUserFoundError && !userUnauthorizedError && (
        <GithubUserCard
          description={description}
          title={title}
          githubUserInfo={githubUserProfile}
        />
      )}

      {noUserFoundError && (
        <Typography variant="subtitle2" gutterBottom component="div" color="error">
          O usuário {githubUserLogin} não está cadastrado na base de dados do Alquimia. Solicite o
          acesso à organização <i>{githubOrg}</i> do GitHub através do{' '}
          <Link
            className={S.ErrorLink}
            href="https://grupoboticario.identitynow.com/ui/d/request-center/request-access"
          >
            MAGB
          </Link>
          .
        </Typography>
      )}

      {userUnauthorizedError && (
        <Typography variant="subtitle2" gutterBottom component="div" color="error">
          O usuário {githubUserLogin} não tem acesso à organização <i>{githubOrg}</i> do GitHub.
          Solicite-o através do{' '}
          <Link
            className={S.ErrorLink}
            href="https://grupoboticario.identitynow.com/ui/d/request-center/request-access"
          >
            MAGB
          </Link>
          .
        </Typography>
      )}
    </FormControl>
  );
};

export const GBTechGetGithubUserInfoFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechGetGithubUserInfo,
    name: 'GBTechGetGithubUserInfo'
  })
);
