import React from 'react';

import Link from '@mui/material/Link';

import { useStyles } from './styles';

export function SuccessStep() {
  const S = useStyles();

  return (
    <p className={S.Text}>
      The team data has already been collected and is being processed,
      {' '}
      <b>this may take some time</b>
      . You can follow the processing logs at the
      bottom of the page.
      <br />
      <br />
      If you have any doubts, feel free to reach us on the channel
      {' '}
      <Link
        href="https://join.slack.com/share/enQtNjQ5MDI4MzMxNDkzMi02MGU1MGJkZWZiZjFjZWE4MjVkZjU2NzI1ODVlNmU5OGU1MTdlZTczY2YzMGE1ZTFkODQyNGZkMjliNTE3M2Zh"
        color="primary"
      >
        #ajuda-opsgenie
      </Link>
    </p>
  );
}
