import React, { useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import Chip from '@mui/material/Chip';

import { discoveryApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import { InfoCard } from '@backstage/core-components';

import { PROVIDED_SERVICES } from './providedServices';
import { useStyles } from './styles';
import * as T from './types';
import { UseCustomFieldDomainFacade } from '../../../facade/useCustomFieldDomainFacade';

export default function CostsSummary({ formData }: T.CostsSummaryProps) {
  const S = useStyles();

  const costs = formData?.services?.filter((element: Record<string, any>) => {
    return Object.keys(element).length !== 0;
  });

  const valueStream = UseCustomFieldDomainFacade.getDomain();

  const [costsData, setCostsData] = useState<T.EstimatedCost[]>([]);

  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const getFinopsCostsData = async () => {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const credentials = await identityApi.getCredentials();

      const { data }: T.GetEstimatedCostsData = await fetch(
        `${baseUrl}finops/getEstimatedCostsData/${valueStream}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${credentials.token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then((res) => res.json());

      setCostsData(data.estimatedCosts);
      return data;
    } catch (error) {
      return error;
    }
  };

  const getServiceCost = (serviceName: string) => {
    const service = costsData.find((cost) => cost.service === serviceName);
    return service?.estimatedCost || 'Unmapped';
  };

  const getServiceList = () => {
    const serviceList: T.Service[] = [];
    let totalCost = 0;

    costs?.forEach((cost: { service: string | number }) => {
      const serviceData = PROVIDED_SERVICES[cost?.service];
      const costValue = getServiceCost(serviceData?.service);

      serviceList.push({
        estimatedCost: costValue,
        service: serviceData?.service,
        label: serviceData?.label
      });

      if (costValue !== 'Unmapped') {
        totalCost += costValue;
      }
    });

    return { services: serviceList, totalCost: totalCost.toFixed(2) };
  };

  const serviceList = getServiceList();

  useEffect(() => {
    if (valueStream) {
      getFinopsCostsData();
    }
  }, [valueStream]);

  return (
    <InfoCard title="Summary" noPadding titleTypographyProps={{ component: 'h2' }}>
      <FormGroup className={S.Summary}>
        <h3 className={S.Title}>
          Component Services <Chip label={costs?.length} size="small" />
        </h3>
        <div className={S.ServiceList}>
          {serviceList?.services.map((cost) => (
            <dl key={cost.label} className={S.Service}>
              <dt className={S.ServiceText}>{cost?.label}</dt>
              {cost.estimatedCost === 'Unmapped' ? (
                <Chip className={S.UnmappedChip} label="UNMAPPED COST" size="small" />
              ) : (
                <dd className={S.ServiceValue}>USD {cost.estimatedCost}</dd>
              )}
            </dl>
          ))}
        </div>
        <Divider className={S.Divider} />
        <div className={S.TotalCostCard}>
          <p className={S.TotalCostText}>Total:</p>
          <h2 className={S.TotalCostValue}>USD {serviceList?.totalCost}</h2>
          <p className={S.TotalCostHint}>(Estimated cost based on previous data)</p>
        </div>
      </FormGroup>
    </InfoCard>
  );
}
