import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-use';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';

import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';
import { useApi } from '@backstage/core-plugin-api';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const GBTechEntityTagPicker = ({
  onChange,
  schema: { title = 'Tags', description = 'Entity Tags' },
  uiSchema,
  rawErrors,
  formData
}: FieldExtensionComponentProps<string[]>) => {
  const uiOptions = 'ui:options';
  const multiple = uiSchema[uiOptions]?.multiple as boolean | false;
  const allowNewValue = uiSchema[uiOptions]?.multiple as boolean | false;
  const catalogApi = useApi(catalogApiRef);

  const [selectedTags, setSelectedTags] = useState<string[]>(formData || []);

  const { value: availableTags } = useAsync(async () => {
    const entities = await catalogApi.getEntities({
      fields: ['metadata.tags']
    });
    const tags = [
      ...new Set(
        entities.items
          .flatMap(({ metadata }) => (metadata?.tags ? metadata.tags : []))
          .filter(Boolean)
      )
    ].sort();

    return tags;
  });

  useEffect(() => {
    onChange(selectedTags);
  }, [selectedTags]);

  const onSelect = async (___: any, value: string | string[] | null) => {
    if (typeof value === 'string') {
      setSelectedTags([value]);
    } else if (value) {
      setSelectedTags(value);
    } else {
      setSelectedTags([]);
    }
  };

  return (
    <React.Fragment>
      <Autocomplete
        multiple={multiple}
        freeSolo={allowNewValue}
        aria-label="Tags"
        options={availableTags || []}
        value={selectedTags}
        data-testid="autocomplete-tagsPicker"
        getOptionLabel={(value) => {
          return typeof value === 'string' ? value : '';
        }}
        onChange={onSelect}
        renderOption={(props, option, { selected }) => (
          <li {...props} key={props.key}>
            <FormControlLabel
              control={<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />}
              label={option}
            />
          </li>
        )}
        popupIcon={<ExpandMoreIcon data-testid="tag-picker-expand" />}
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid="tag-input"
            label={title}
            helperText={description}
            margin="normal"
            variant="outlined"
            required={!selectedTags?.length}
            error={rawErrors?.length > 0 && !selectedTags}
          />
        )}
      />
    </React.Fragment>
  );
};

export const GBTechEntityTagPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechEntityTagPicker,
    name: 'GBTechEntityTagPicker'
  })
);
