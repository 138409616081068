import React from 'react';

import { RenderLink } from '../../RenderLink';
import type { OwnerProps } from './types';

export const Owner = ({ owner, namespace }: OwnerProps) => {
  const removeNamespaceFromValue = (value: string) => value.replace(`${namespace}/`, '');

  return (
    <RenderLink
      resultProperty={owner}
      title={'OWNER'}
      href={`/catalog/${namespace}/group/${removeNamespaceFromValue(owner)}`}
    />
  );
};
