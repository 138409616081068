import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cont: {
    padding: '20px',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleCard: {
    margin: '0px 0px 20px 0px',
    fontSize: '24px',
  },
  list: {
    maxHeight: '40vh',
    overflowY: 'auto',
  },
  link: {
    textDecoration: 'none',
  },
  envItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    borderRadius: '6px',
    padding: '10px',
    backgroundColor: theme.palette.background.default,
    justifyContent: 'space-between',
  },
}));
