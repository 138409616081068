import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  contEnv: {
    padding: '20px',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: '0px 0px 10px 0px',
    fontSize: '21px',
  },
  infraDetailsCard: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '24px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.text.secondary,
    gap: '24px',
    padding: '10px',
    borderRadius: '4px',
    '& > p': {
      margin: 0,
      fontSize: '12px',
    },
  },
  line: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '24px',
  },
}));
