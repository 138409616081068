import React, { useContext, useState } from 'react';

import Button from '@mui/material/Button';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { merge } from 'lodash';

import { EditState } from './EditState';
import { ViewState } from './ViewState';
import { OpsGenieContext } from '..';
import { useStyles } from './style';
import * as T from './types';

export function EscalationRules({
  onChange,
  escalationRules,
  defaultRule,
}: T.EscalationRulesProps) {
  const S = useStyles();
  const isAdmin = useContext(OpsGenieContext).isAdmin;

  const [ruleList, setRuleList] = useState<T.EscalationRule[]>(escalationRules);

  const editRule = (index: number, property: Record<string, any>) => {
    const editedRules = ruleList.map((item, i) => {
      if (i === index) {
        return merge({}, item, property) as T.EscalationRule;
      }

      return item;
    });

    setRuleList(editedRules);
    onChange(editedRules);
  };

  const addNewRule = () => {
    setRuleList([
      ...ruleList,
      {
        condition: '',
        delay: {
          timeAmount: 0,
          timeUnit: 'minutes',
        },
        notifyType: '',
        recipient: {
          type: '',
          id: '',
          email: '',
        },
        variant: 'edit',
      },
    ]);
  };

  const deleteRule = (index: number) => {
    const filteredRules = ruleList.filter((_, i) => i !== index);

    setRuleList(filteredRules);
    onChange(filteredRules);
  };

  return (
    <div className={S.Container}>
      {defaultRule && (
        <div className={S.RuleCard}>
          <ViewState rule={defaultRule} disabled />
        </div>
      )}

      {ruleList.map((rule, index) => (
        <div className={S.RuleCard} key={index}>
          <div className={S.Field}>
            {rule.variant === 'edit' ? (
              <EditState
                onChange={value => editRule(index, value)}
                onCancel={() => deleteRule(index)}
                escalationRule={rule}
              />
            ) : (
              <>
                <ViewState rule={rule} />
                {isAdmin && (
                  <div className={S.Buttons}>
                    <Button
                      className={S.Button}
                      onClick={() => editRule(index, { variant: 'edit' })}
                    >
                      <EditIcon data-testid={`edit-rule-${index}`} />
                    </Button>
                    <Button
                      className={S.Button}
                      onClick={() => deleteRule(index)}
                    >
                      <DeleteIcon data-testid={`delete-rule-${index}`} />
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ))}

      {isAdmin && (
        <Button
          className={S.AddRules}
          color="primary"
          href="#text-buttons"
          startIcon={<AddIcon />}
          onClick={addNewRule}
        >
          Add Rule
        </Button>
      )}
    </div>
  );
}
