import React from 'react';

import { EntityLayout } from '@backstage/plugin-catalog';

import Grid from '@mui/material/Grid';

import { EntityWarningContent } from '../Contents/EntityWarning';
import { EnvironmentConfigCard } from './EnvironmentConfigCard';
import EntityLayoutWrapper from '../../../components/EntityLayoutWrapper';
import { AboutCard } from '../Contents/AboutCard';

export const EnvironmentPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={12}>
          <AboutCard hrefTechDocs="/docs/default/component/alquimia/configuration/environment/" />
        </Grid>
        <Grid item xs={12} md={12}>
          <EnvironmentConfigCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
