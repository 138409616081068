import { useCustomDomainFieldDomainStore } from '../store/useCustomFieldDomainStore';

export class UseCustomFieldDomainFacade {
  static setDomain(domain: string) {
    useCustomDomainFieldDomainStore.getState().setDomain(domain);
  }
  static getDomain() {
    return useCustomDomainFieldDomainStore.getState().domain;
  }
}
