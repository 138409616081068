import React, { useState, useEffect, useRef } from 'react';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

import { AddBox, DeleteOutlineRounded } from '@mui/icons-material';

interface Key {
  name: string;
  dataType: string;
  isPartitionKey: boolean;
  isSortKey: boolean;
}

interface Index {
  name: string;
  hashKey: string;
  rangeKey: string;
  projection: 'KEYS_ONLY' | 'ALL' | 'INCLUDE';
  nonKeyAttributes?: string;
}

export interface DynamoDBTableSchema {
  keys: Key[];
  globalIndexes?: Index[];
  localIndexes?: Index[];
}

interface FieldErrors {
  keys?: string[];
  gsi?: string[];
  lsi?: string[];
}

const validateKeys = (keys: Key[]): string[] => {
  const errors: string[] = [];

  if (keys.length === 0) errors.push('A tabela deve ter pelo menos uma key');
  if (keys.some((k) => !k.name)) errors.push('Por favor informe o nome de todas keys');
  if (keys.some((k) => !k.dataType))
    errors.push('Por favor informe o data type para todas as keys');
  if (keys.length > 0 && keys.every((k) => !k.isPartitionKey))
    errors.push('A tabela deve ter uma Partition Key');

  return errors;
};

const validateIndex = (indexes: Index[], indexTypeName: 'Global' | 'Local'): string[] => {
  const errors: string[] = [];

  if (indexes.some((k) => !k.name))
    errors.push(`Por favor informe o nome de todos ${indexTypeName} Secondary Indexes`);
  if (indexes.some((k) => !k.hashKey))
    errors.push(`Por favor informe a hash key de todos ${indexTypeName} Secondary Indexes`);
  if (indexes.some((k) => k.projection === 'INCLUDE' && !k.nonKeyAttributes))
    errors.push(
      `Informe os atributos non-key para ${indexTypeName} Secondary Indexes com "Include" projection type`
    );

  return errors;
};

const validateSchema = (schema: DynamoDBTableSchema): FieldErrors => {
  const schemaErrors: FieldErrors = {};
  schemaErrors.keys = validateKeys(schema.keys);

  if (schema.globalIndexes) {
    schemaErrors.gsi = validateIndex(schema.globalIndexes, 'Global');
  }

  if (schema.localIndexes) {
    schemaErrors.lsi = validateIndex(schema.localIndexes, 'Local');
  }

  return schemaErrors;
};

const changeSchema = (schema: DynamoDBTableSchema, onChange: any): FieldErrors => {
  const schemaErrors = validateSchema(schema);
  if (
    schemaErrors.keys?.length === 0 &&
    schemaErrors.gsi?.length === 0 &&
    schemaErrors.lsi?.length === 0
  ) {
    onChange(schema);
  } else {
    onChange({});
  }
  return schemaErrors;
};

export const GbTechDynamoDBTableSchema = ({
  onChange,
  formData
}: FieldExtensionComponentProps<DynamoDBTableSchema>) => {
  const firstUpdate = useRef(true);

  const [keys, setKeys] = useState<Key[]>([]);
  const [localIndexes, setLSI] = useState<Index[]>([]);
  const [globalIndexes, setGSI] = useState<Index[]>([]);
  const [errors, setErrors] = useState<FieldErrors>();

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      if (formData?.keys && formData.keys.length > 0) {
        setKeys(formData.keys);
      }

      if (formData?.globalIndexes && formData.globalIndexes.length > 0) {
        setGSI(formData.globalIndexes);
      }

      if (formData?.localIndexes && formData.localIndexes.length > 0) {
        setLSI(formData.localIndexes);
      }
    }

    setErrors(
      changeSchema(
        {
          keys,
          globalIndexes,
          localIndexes
        },
        onChange
      )
    );
  }, [keys, globalIndexes, localIndexes]);

  const updateItemProperty = (
    event: any,
    index: number,
    propertyToUpdate: string,
    list: any[],
    setList: React.SetStateAction<any>
  ) => {
    const newObjectProperty: any = {};
    newObjectProperty[propertyToUpdate] = typeof event === 'string' ? event : event?.target?.value;
    setList(
      list.map((item, _index) =>
        index === _index ? Object.assign(item, newObjectProperty) : { ...item }
      )
    );
  };

  const changePartitionKey = (selected: any, keyIndex: number) => {
    const newValue = typeof selected === 'string' ? selected : selected?.target?.value;
    const updatedKeys = keys.map((k, index) =>
      index === keyIndex
        ? { ...k, isPartitionKey: newValue === '1', isSortKey: false }
        : { ...k, isPartitionKey: false }
    );

    setKeys(updatedKeys);
    const partitionKeyName = updatedKeys?.find((k) => k.isPartitionKey)?.name;
    localIndexes.forEach((lsi, index) => {
      updateItemProperty(partitionKeyName, index, 'hashKey', localIndexes, setLSI);
      if (lsi.rangeKey === partitionKeyName) {
        updateItemProperty('', index, 'rangeKey', localIndexes, setLSI);
      }
    });
  };

  const changeSortKey = (selected: any, keyIndex: number) => {
    const newValue = typeof selected === 'string' ? selected : selected?.target?.value;
    setKeys(
      keys.map((k, index) =>
        index === keyIndex
          ? { ...k, isSortKey: newValue === '1', isPartitionKey: false }
          : { ...k, isSortKey: false }
      )
    );
  };

  const changeHashKey = (
    selected: any,
    keyIndex: number,
    indexList: any[],
    setList: React.SetStateAction<any>
  ) => {
    const newValue = typeof selected === 'string' ? selected : selected?.target?.value;
    setList(
      indexList.map((k, index) =>
        index === keyIndex
          ? {
              ...k,
              hashKey: newValue,
              rangeKey: newValue !== k.rangeKey ? k.rangeKey : ''
            }
          : { ...k }
      )
    );
  };

  const changeRangeKey = (
    selected: any,
    keyIndex: number,
    indexList: any[],
    setList: React.SetStateAction<any>
  ) => {
    const newValue = typeof selected === 'string' ? selected : selected?.target?.value;
    setList(
      indexList.map((k, index) =>
        index === keyIndex
          ? {
              ...k,
              rangeKey: newValue,
              hashKey: newValue !== k.hashKey ? k.hashKey : ''
            }
          : { ...k }
      )
    );
  };

  const changeIndexProjection = (
    selected: any,
    keyIndex: number,
    indexList: any[],
    setList: React.SetStateAction<any>
  ) => {
    const newValue = typeof selected === 'string' ? selected : selected?.target?.value;
    setList(
      indexList.map((k, index) =>
        index === keyIndex
          ? {
              ...k,
              projection: newValue,
              nonKeyAttributes: newValue !== 'INCLUDE' ? undefined : k.nonKeyAttributes
            }
          : { ...k }
      )
    );
  };

  const keySchemaRow = (k: Key, index: number) => (
    <Grid key={index} data-testid="keyrow" container spacing={1}>
      <Grid item xs={4}>
        <TextField
          onChange={(e) => updateItemProperty(e, index, 'name', keys, setKeys)}
          value={k.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          id="dynamo-attribute-data-type"
          label="Data Type"
          value={k.dataType}
          onChange={(selected) => updateItemProperty(selected, index, 'dataType', keys, setKeys)}
          fullWidth
        >
          <MenuItem value="" />
          <MenuItem value="S">String</MenuItem>
          <MenuItem value="N">Number</MenuItem>
          <MenuItem value="B">Binary</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Radio
          value="1"
          data-testid="rd-partitionkey"
          name="isPartitionKey"
          onChange={(selected) => changePartitionKey(selected, index)}
          checked={k.isPartitionKey}
        />
      </Grid>
      <Grid item xs={2}>
        <Radio
          value="1"
          data-testid="rd-sortkey"
          name="isSortKey"
          onChange={(selected) => changeSortKey(selected, index)}
          checked={k.isSortKey}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton
          title="Remove this Key from Schema"
          color="secondary"
          onClick={() => setKeys(keys.filter((_, idx) => idx !== index))}
          size="small"
        >
          <DeleteOutlineRounded />
        </IconButton>
      </Grid>
    </Grid>
  );

  const globalIndexRow = (i: Index, index: number) => (
    <Grid key={index} data-testid="gsirow" container spacing={1}>
      <Grid item xs={4}>
        <TextField
          onChange={(e) => updateItemProperty(e, index, 'name', globalIndexes, setGSI)}
          value={i.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          id="dynamo-gsi-hashkey"
          label="Hash Key"
          value={i.hashKey}
          onChange={(e) => changeHashKey(e, index, globalIndexes, setGSI)}
          fullWidth
        >
          <MenuItem value="" />
          {keys.map((k) => (
            <MenuItem key={k.name} value={k.name}>{k.name}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Select
          id="dynamo-gsi-rangekey"
          label="Range Key"
          value={i.rangeKey}
          onChange={(e) => changeRangeKey(e, index, globalIndexes, setGSI)}
          fullWidth
        >
          <MenuItem value="" />
          {keys.map((k) => (
            <MenuItem key={k.name} value={k.name}>{k.name}</MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Select
          id="dynamo-gsi-projection"
          label="Projection"
          value={i.projection}
          onChange={(selected) => changeIndexProjection(selected, index, globalIndexes, setGSI)}
          fullWidth
        >
          <MenuItem value="KEYS_ONLY">Keys Only</MenuItem>
          <MenuItem value="ALL">All</MenuItem>
          <MenuItem value="INCLUDE">Include</MenuItem>
        </Select>
        {i.projection === 'INCLUDE' && (
          <TextField
            placeholder="Please inform all Non-Key Attributes in comma separated format"
            onChange={(e) =>
              updateItemProperty(e, index, 'nonKeyAttributes', globalIndexes, setGSI)
            }
            value={i.nonKeyAttributes}
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton
          title="Remove this Index"
          color="secondary"
          onClick={() => setGSI(globalIndexes.filter((_, idx) => idx !== index))}
          size="small"
        >
          <DeleteOutlineRounded />
        </IconButton>
      </Grid>
    </Grid>
  );

  const localIndexRow = (i: Index, index: number) => (
    <Grid key={index} data-testid="lsirow" container spacing={1}>
      <Grid item xs={4}>
        <TextField
          onChange={(e) => updateItemProperty(e, index, 'name', localIndexes, setLSI)}
          value={i.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <TextField value={i.hashKey} inputProps={{ readOnly: true }} fullWidth />
      </Grid>
      <Grid item xs={2}>
        <Select
          id="dynamo-lsi-rangekey"
          label="Range Key"
          value={i.rangeKey}
          onChange={(e) => updateItemProperty(e, index, 'rangeKey', localIndexes, setLSI)}
          fullWidth
        >
          <MenuItem value="" />
          {keys.map((k) => (!k.isPartitionKey ? <MenuItem key={k.name} value={k.name}>{k.name}</MenuItem> : ''))}
        </Select>
      </Grid>
      <Grid item xs={2}>
        <Select
          id="dynamo-lsi-projection"
          label="Projection"
          value={i.projection}
          onChange={(selected) => changeIndexProjection(selected, index, localIndexes, setLSI)}
          fullWidth
        >
          <MenuItem value="KEYS_ONLY">Keys Only</MenuItem>
          <MenuItem value="ALL">All</MenuItem>
          <MenuItem value="INCLUDE">Include</MenuItem>
        </Select>
        {i.projection === 'INCLUDE' && (
          <TextField
            placeholder="Please inform all non-Key Attributes in comma separated format"
            onChange={(e) => updateItemProperty(e, index, 'nonKeyAttributes', localIndexes, setLSI)}
            value={i.nonKeyAttributes}
            fullWidth
          />
        )}
      </Grid>
      <Grid item xs={1}>
        <IconButton
          title="Remove this Index"
          color="secondary"
          onClick={() => setLSI(localIndexes.filter((_, idx) => idx !== index))}
          size="small"
        >
          <DeleteOutlineRounded />
        </IconButton>
      </Grid>
    </Grid>
  );

  return (
    <React.Fragment>
      <Box paddingBottom={2}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <FormLabel id="key-schema">Keys Schema</FormLabel>
            <IconButton
              data-testid="btn-newkey"
              title="Add New Key"
              color="primary"
              size="small"
              onClick={() =>
                setKeys((prev) => [
                  ...prev,
                  {
                    name: '',
                    dataType: '',
                    isSortKey: false,
                    isPartitionKey: false
                  }
                ])
              }
            >
              <AddBox />
            </IconButton>
            <Typography variant="subtitle2">
              Describe all attributes that will be used as keys for the table itself and for the
              indexes
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Name
          </Grid>
          <Grid item xs={2}>
            Data Type
          </Grid>
          <Grid item xs={2}>
            Partition Key?
          </Grid>
          <Grid item xs={2}>
            Sort Key?
          </Grid>
        </Grid>
        {keys.map((k, index) => keySchemaRow(k, index))}
        {errors?.keys && (
          <FormGroup>
            {errors.keys.map((e, i) => (
              <FormHelperText key={i} error={true}>
                {e}
              </FormHelperText>
            ))}
          </FormGroup>
        )}
      </Box>

      <Box paddingBottom={2}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <FormLabel required={false} id="gsi-keys">
              Global Secondary Indexes
            </FormLabel>
            <IconButton
              data-testid="btn-newgsi"
              title="Add New Global Secondary Index"
              color="primary"
              size="small"
              onClick={() =>
                setGSI((prev) => [
                  ...prev,
                  {
                    name: '',
                    hashKey: '',
                    rangeKey: '',
                    projection: 'KEYS_ONLY'
                  }
                ])
              }
            >
              <AddBox />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Name
          </Grid>
          <Grid item xs={2}>
            Hash Key
          </Grid>
          <Grid item xs={2}>
            Range Key
          </Grid>
          <Grid item xs={2}>
            Projection
          </Grid>
        </Grid>
        {globalIndexes.map((k, index) => globalIndexRow(k, index))}
        {errors?.gsi && (
          <FormGroup>
            {errors.gsi.map((e, i) => (
              <FormHelperText key={i} error={true}>
                {e}
              </FormHelperText>
            ))}
          </FormGroup>
        )}
      </Box>

      <Box paddingBottom={2}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <FormLabel required={false} id="lsi-keys">
              Local Secondary Indexes
            </FormLabel>
            <IconButton
              data-testid="btn-newlsi"
              title="Add New Local Secondary Index"
              color="primary"
              size="small"
              onClick={() =>
                setLSI((prev) => [
                  ...prev,
                  {
                    name: '',
                    hashKey: keys?.find((k) => k.isPartitionKey)?.name ?? '',
                    rangeKey: '',
                    projection: 'KEYS_ONLY'
                  }
                ])
              }
            >
              <AddBox />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            Name
          </Grid>
          <Grid item xs={2}>
            Hash Key
          </Grid>
          <Grid item xs={2}>
            Range Key
          </Grid>
          <Grid item xs={2}>
            Projection
          </Grid>
        </Grid>
        {localIndexes.map((k, index) => localIndexRow(k, index))}
        {errors?.lsi && (
          <FormGroup>
            {errors.lsi.map((e, i) => (
              <FormHelperText key={i} error={true}>
                {e}
              </FormHelperText>
            ))}
          </FormGroup>
        )}
      </Box>
    </React.Fragment>
  );
};

export const GbTechDynamoDBTableSchemaFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GbTechDynamoDBTableSchema,
    name: 'GbTechDynamoDBTableSchema'
  })
);
