import React, { useEffect, useState } from 'react';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

interface fieldErrors {
  message: string | undefined;
  status: boolean;
}

export const GBTechRestrictedNames = ({
  schema,
  required,
  onChange,
  formData,
  uiSchema,
}: FieldExtensionComponentProps<string>) => {
  const options = 'ui:options';
  const restrictedListName = uiSchema[options]?.name as string;
  const [fieldStatus, setFieldStatus] = useState<fieldErrors>({
    status: false,
    message: schema.description,
  });
  const config = useApi(configApiRef);
  const [fieldName, setFieldName] = useState<string>();

  let filterTimeout: any;

  const list: Array<string> = config.get(
    `restrictedNames.${restrictedListName}`,
  );
  const checkRestrictedNames = (inputValue: string) => {
    setFieldName(inputValue);
    setFieldStatus({ status: false, message: schema.description });
    clearTimeout(filterTimeout);

    filterTimeout = setTimeout(() => {
      onChange(inputValue);

      if (list.includes(inputValue)) {
        setFieldStatus({
          status: true,
          message:
            'This is a restricted name and cannot be assigned to this field.',
        });
        onChange('');
      }
    }, 500);
  };

  useEffect(() => {
    if (formData) {
      checkRestrictedNames(formData);
      setFieldName(formData);
    }
  }, []);

  return (
    <FormControl>
      <TextField
        id="gbtech-restrictedname-input"
        error={fieldStatus?.status}
        label={schema.title}
        onChange={event => checkRestrictedNames(event.target.value)}
        margin="normal"
        helperText={fieldStatus?.message}
        variant="standard"
        required={required}
        value={fieldName ?? ''}
      />
    </FormControl>
  );
};

export const GBTechRestrictedNamesFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechRestrictedNames,
    name: 'GBTechRestrictedNames',
  }),
);
