import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';

export const useStyles = makeStyles((theme:Theme) => ({
  Form: {
    maxWidth: '100%',
    width: 350,
  },
  CardAutomation: {
    marginTop: 18,
  },
  Button: {
    marginTop: 18,
  },
  AutomationSelect: {
    width: 350,
  },
  Title: {
    margin: 0,
  },
  Description: {
    margin: '8px 0px',
  },
  MenuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
  },
}));
