import React from 'react';

import Button from '@mui/material/Button';

import DescriptionIcon from '@mui/icons-material/Description';

import { useStyles } from './styles';
import type { ViewSystemTechDocsButtonProps } from './types';

export const ViewSystemTechDocsButton = ({ techDocsSystem }:ViewSystemTechDocsButtonProps) => {
    const S = useStyles();
    return (
      <Button
        className={S.buttonAction}
        variant="contained"
        color="primary"
        component="a"
        href={techDocsSystem}
        target="_blank"
      >
        <DescriptionIcon className={S.iconsButton} />
        View System TechDocs
      </Button>
    );
  };