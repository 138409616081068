import React from 'react';

import { RenderStatus } from '../../RenderStatus';
import type { StatusProps } from './types';

export const Status = ({ enabled, kind }: StatusProps) => {
  return (
    <RenderStatus
      resultProperty={enabled ? 'habilitado' : 'desabilitado'}
      title={kind.toUpperCase()}
    />
  );
};
