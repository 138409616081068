export const parseGithubRepoUrl = (input: string) => {
  const githubUrlRegex = new RegExp(
    /((git|ssh|http(s)?)(:(\/\/)?)|(git@))(github.com)[/:](?<org>[\w.@:\-~]+)\/(?<repo>[\w@:\-~]+)/i
  );
  const urlMatch = githubUrlRegex.exec(input);
  if (urlMatch && urlMatch.length > 0 && urlMatch.groups?.org && urlMatch.groups?.repo) {
    return { org: urlMatch.groups.org, repo: urlMatch.groups.repo };
  }
  return null;
};
