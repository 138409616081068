import React from 'react';

import { RenderLink } from '../../RenderLink';
import type { DomainProps } from './types';

export const Domain = ({ domain }: DomainProps) => {
  return (
    <RenderLink
      resultProperty={domain}
      title={'DOMAIN'}
      href={`/catalog/default/domain/${domain}`}
    />
  );
};

