import React from 'react';

import { useStyles } from './styles';
import type { DevOpsTeamProps } from './types';

export const DevOpsTeam = ({ team, namespace }: DevOpsTeamProps) => {
  const S = useStyles();
  return (
    <div className={S.itemDetails}>
      <p className={S.titleProperty}>DEVOPS TEAM</p>
      <p className={S.resultProperty}>
        <a
          href={`/catalog/${namespace}/group/${team}`}
          target="_blank"
          rel="noreferrer"
          className={S.link}
        >
          {team}
        </a>
      </p>
    </div>
  );
};
