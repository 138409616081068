import React from 'react';

import { ViewSourceButton } from './ViewSourceButton';
import { ViewTechDocsButton } from './ViewTechDocsButton';
import { ViewSystemTechDocsButton } from './ViewSystemTechDocsButton';
import { useStyles } from './styles';
import  type { ActionsButtonsProps } from './types';

export const ActionsButtons = ({ hrefTechDocs, viewUrl, techDocs, techDocsSystem }: ActionsButtonsProps) => {
    const S = useStyles();
    const hasButtons = viewUrl || hrefTechDocs || techDocs || techDocsSystem;
    if (!hasButtons) return null;
    return (
      <div className={S.contActions}>
        <div>
          <p className={S.titleProperty}>ACTIONS</p>
        </div>
        {viewUrl && <ViewSourceButton viewUrl={viewUrl} />}
        {(hrefTechDocs || techDocs) && <ViewTechDocsButton hrefTechDocs={hrefTechDocs} techDocs={techDocs} />}
        {techDocsSystem && <ViewSystemTechDocsButton techDocsSystem={techDocsSystem} />}
      </div>
    );
  };