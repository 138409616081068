import React from 'react';

import { RenderStatus } from '../../RenderStatus';
import type { LifecycleProps }  from './types';

export const Lifecycle = ({ lifecycle }: LifecycleProps) => {
  return (
    <RenderStatus
      resultProperty={lifecycle}
      title={'LIFECYCLE'}
    />
  );
};
