import React, { useContext, useEffect, useState } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete from '@mui/material/Autocomplete';

import * as T from './types';
import { OpsGenieContext } from '../..';

export function TeamInformationStep({ onChange }: T.TeamInformationStepProps) {
  const domainList = useContext(OpsGenieContext).domainList;

  const [stepData, setStepData] = useState<T.TeamInformationFormData>({
    name: '',
    description: '',
    domain: '{domain}',
    isFilled: false
  });
  const [nameError, setNameError] = useState(false);

  const checkIfFilled = () => {
    return Boolean(stepData.name && stepData.description && stepData.domain);
  };

  const handleChange = (value: string, field: 'description' | 'domain') => {
    setStepData({
      ...stepData,
      [field]: value
    });
  };

  const handleNameChange = (value: string, validity: any) => {
    if (value === null) {
      return;
    }
    if (validity.valid) {
      setNameError(false);
      setStepData({
        ...stepData,
        ['name']: value
      });
    } else {
      setNameError(true);
      setStepData({
        ...stepData,
        ['name']: ''
      });
    }
  };

  useEffect(() => {
    onChange({ ...stepData, isFilled: checkIfFilled() });
  }, [stepData]);

  return (
    <>
      <Autocomplete
        options={domainList}
        getOptionLabel={(option) => option}
        noOptionsText="Not found"
        onChange={(_, changedValue) => changedValue && handleChange(changedValue, 'domain')}
        renderInput={(params) => (
          <TextField
            {...params}
            type="text"
            label="Domain / Value Stream / Chapter"
            variant="outlined"
            fullWidth
            placeholder="Team's Domain / Value Stream / Chapter"
          />
        )}
      />
      <TextField
        type="text"
        label="Name"
        variant="outlined"
        fullWidth
        placeholder="Team name"
        required
        InputProps={{
          startAdornment: <InputAdornment position="start">ops-{stepData.domain}-</InputAdornment>
        }}
        inputProps={{
          pattern: '[a-z0-9_]+'
        }}
        error={nameError}
        helperText={nameError ? '(Somente letras minúsculas, números e sublinhados)' : ''}
        onChange={(e) => handleNameChange(e.target.value, e.target.validity)}
      />
      <TextField
        type="text"
        label="Description"
        variant="outlined"
        fullWidth
        placeholder="Team description"
        required
        onChange={(e) => handleChange(e.target.value, 'description')}
      />
    </>
  );
}
