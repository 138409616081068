import React from 'react';

import { EntityLayout } from '@backstage/plugin-catalog';

import Grid from '@mui/material/Grid';

import { EntityWarningContent } from '../Contents/EntityWarning';
import { EnvironmentSetCard } from './EnvironmentSetCard';
import EntityLayoutWrapper from '../../../components/EntityLayoutWrapper';
import { AboutCard } from '../Contents/AboutCard';

export const EnvironmentSetPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={12}>
          <AboutCard hrefTechDocs="/docs/default/component/alquimia/configuration/environmentSet/" />
        </Grid>
        <Grid item xs={12} md={12}>
          <EnvironmentSetCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
