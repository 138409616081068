import React from 'react';

import IconButton from '@material-ui/core/IconButton';

import CachedIcon from '@material-ui/icons/Cached';
import EditIcon from '@material-ui/icons/Edit';

import { useStyles } from './styles';
import type { ActionsIconsProps } from './types';

export const ActionsIcons = ({ refreshEntity, hrefEdit, editSystem, editComponent }:ActionsIconsProps) => {
  const S = useStyles();
  return (
    <div>
      <IconButton className={S.iconsButton} onClick={refreshEntity}>
        <CachedIcon />
      </IconButton>
      <IconButton
        className={S.iconsButton}
        component="a"
        href={hrefEdit || editSystem || editComponent}
        target="_blank"
      >
        <EditIcon />
      </IconButton>
    </div>
  );
};