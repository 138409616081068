import React from 'react';

import { RenderLink } from '../../RenderLink';
import type { SystemProps } from './types';

export const System = ({ system, namespace }: SystemProps) => {

  const removeNamespaceFromValue = (value: string) => value.replace(`${namespace}/`, '');

  return (
    <RenderLink
      resultProperty={system}
      title={'SYSTEM'}
      href={`/catalog/${namespace}/system/${removeNamespaceFromValue(system)}`}
    />
  );
};
