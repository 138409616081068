import React from 'react';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';

import { useStyles } from './styles';

export const GBRadioGroup = ({
  uiSchema,
  schema,
  onChange,
}: FieldExtensionComponentProps<string>) => {
  const S = useStyles();

  const options = 'ui:options';
  const items = uiSchema[options]?.items as string[];

  const title = schema?.title;

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <>
      <FormControl component="fieldset" className={S.Container}>
        {title && <FormLabel component="legend">{title}</FormLabel>}
        <RadioGroup
          aria-label="componentScope"
          name="componentScope"
          className={S.Container}
          onChange={handleRadioChange}
        >
          {items.map((item: string, index: number) => (
            <FormControlLabel
              key={index}
              value={item}
              control={<Radio color="primary" />}
              label={item}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export const GBRadioGroupFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBRadioGroup,
    name: 'GBRadioGroup',
  }),
);
