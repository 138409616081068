import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  containerType: {
    display: 'flex',
    flexDirection: 'column',
  },
  line: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '24px',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.text.secondary,
    gap: '24px',
    padding: '10px',
    borderRadius: '4px',
    '& > p': {
      margin: 0,
      fontSize: '12px',
    },
  },
  keyValue: {
    display: 'flex',
    flexDirection: 'column',
    '& > p': {
      margin: 0,
      fontSize: '10px',
    },
  },
  key: {
    textTransform: 'uppercase',
    margin: 0,
  },
  value: {
    fontWeight: 'bold',
    margin: 0,
    fontSize: '12px',
  },
  copybutton: {
    display: 'flex',
  },
  icon: {
    padding: '0px',
    fontSize: '1rem',
    marginLeft: '2px',
  },
}));
