import React from 'react';

import { useStyles } from './styles';
import { RenderModal } from '../../RenderModal';
import { EndpointsCard } from '../../EndpointsCard';
import type { EndpointsProps } from './types';

export const Endpoints = ({ entity }: EndpointsProps) => {
    const S = useStyles();
    return (
      <div className={S.contDetails}>
        <p className={S.titleProperty}>ENDPOINTS</p>
        <RenderModal titleLink="show URLs">
          <EndpointsCard {...entity} />
        </RenderModal>
      </div>
    );
  };
