import React from 'react';

import { useEntity } from '@backstage/plugin-catalog-react';

import Chip from '@mui/material/Chip';

import { useStyles } from './styles';
import * as T from './types';

export const RenderTags = ({ title }: T.RenderListProps) => {
  const S = useStyles();
  const { entity } = useEntity();
  const titleProperty = title.toUpperCase();
  let runnerGroup = '';
  if (Array.isArray(entity.spec?.runners)) {
    const runners: Array<{ arch: string; label: string }> = entity.spec
      ?.runners as Array<{ arch: string; label: string }>;
    const defaultRunner = runners.find(
      (runner: { arch: string }) =>
        runner.arch === entity.spec?.defaultRunnerArch,
    );
    runnerGroup = defaultRunner?.label as string;
  } else {
    runnerGroup = entity.spec?.runners as string;
  }
  const resultProperty =
    titleProperty === 'RUNNERS' ? runnerGroup : entity.metadata.tags;

  const normalizedArray = (array: any): { label: string }[] => {
    return array.map((obj: any) => {
      const isObject = typeof obj === 'object' && obj !== null;
      return isObject ? obj : { label: String(obj) };
    });
  };

  const rawValue = resultProperty || '';
  if (!rawValue) return null;

  const items = !Array.isArray(rawValue)
    ? [{ label: String(rawValue) }]
    : normalizedArray(rawValue);
  if (!items.map(item => item.label)) return null;

  return (
    <div className={S.itemDetails}>
      <p className={S.titleProperty}>{titleProperty}</p>
      <div className={S.list}>
        {items.map(item => (
          <Chip
            key={item.label as React.Key}
            label={item.label}
            className={S.listItem}
            size="small"
          />
        ))}
      </div>
    </div>
  );
};
