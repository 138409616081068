import React, { useState } from 'react';

import TextField from '@mui/material/TextField';

import {
  FieldExtensionComponentProps,
  createScaffolderFieldExtension,
} from '@backstage/plugin-scaffolder-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';

export type AwsArnParts = {
  partition?: ['aws', 'aws-cn', 'aws-us-gov'];
  service?: string;
  region?: string;
  accountId?: string;
  resourceType?: string;
  resourceId?: string;
  valid: boolean;
  arnText: string;
};

// Parse AWS ARN using regex
const getArnRegex = () => {
  return /arn:(?<partition>[^:]+):(?<service>[^:]+):(?<region>[^:]+):(?<accountId>[^:]+):((?<resourceType>[^:/]+)[:/])?(?<resourceId>[^:/]+)/gim;
};

export const parseArn = (arn: string): AwsArnParts => {
  const match = getArnRegex().exec(arn);
  if (!match?.groups) {
    throw new Error('ARN Inválido');
  }
  const result: AwsArnParts = {
    partition: match.groups.partition as unknown as [
      'aws',
      'aws-cn',
      'aws-us-gov',
    ],
    service: match.groups.service,
    region: match.groups.region,
    accountId: match.groups.accountId,
    resourceId: match.groups.resourceId,
    resourceType: match.groups.resourceType,
    valid: true,
    arnText: arn,
  };

  return result;
};

export const isArn = (arn: string): boolean => {
  return getArnRegex().test(arn);
};

export const AwsArn = ({
  formData,
  field,
  onChange,
}: FieldExtensionComponentProps<AwsArnParts>) => {
  const [arnParts, setArnParts] = useState<AwsArnParts>({
    valid: true,
    arnText: formData?.arnText || '',
  });

  const handleChange = (arn: string) => {
    if (!isArn(arn)) {
      setArnParts({
        valid: false,
        arnText: arn,
      });
    } else {
      setArnParts(parseArn(arn));
    }
    onChange(arnParts);
  };

  return (
    <TextField
      {...field}
      value={arnParts?.arnText}
      error={arnParts?.valid ? undefined : 'ARN inválido'}
      onChange={e => handleChange(e.target.value)}
      onBlur={e => handleChange(e.target.value)}
      fullWidth
      helperText="Indique um ARN (AW Resource Name) válido"
      required
    />
  );
};

export const AwsArnFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: AwsArn,
    name: 'AwsArn',
    validation: (data: AwsArnParts, fieldValidation: any) => {
      if (!data.valid) {
        fieldValidation.addError(`ARN inválido: ${data.arnText}`);
      }
    },
  }),
);
