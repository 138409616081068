import React, { useEffect } from 'react';

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';
import { UseCustomFieldDomainFacade } from '../../../../facade/useCustomFieldDomainFacade';

type CloudProviderOptions = 'AWS' | 'Azure' | 'GCP';

export const GBReviewTerraform = ({
  formContext,
  onChange
}: FieldExtensionComponentProps<string>) => {
  const { formData } = formContext;
  const domain = UseCustomFieldDomainFacade.getDomain();

  const providerOptions = {
    AWS: 'aws',
    Azure: 'azr',
    GCP: 'gcp'
  };

  const getComponentScope = (scope: string) => {
    let formattedScope = '';

    if (scope === 'Value Stream') {
      formattedScope = domain;
    } else if (scope === 'Project') {
      formattedScope = formData?.component?.name?.replaceAll(' ', '-');
    }

    return formattedScope;
  };

  const cloudProvider =
    providerOptions[formData.cloudProvider as CloudProviderOptions] || 'cloudprovider';
  const componentScope = getComponentScope(formData.componentScope) || 'scope';

  const terraformRepoName = `tf-${cloudProvider}-${componentScope}-resources`.toLocaleLowerCase();

  useEffect(() => {
    onChange(terraformRepoName);
  }, [terraformRepoName]);

  return (
    <FormControl>
      <TextField
        id="terraform-review-name"
        label="Terraform Repository Name"
        title="Terraform Repository Name"
        margin="normal"
        variant="outlined"
        disabled
        value={terraformRepoName}
      />
      <FormHelperText>
        This is a preview of your repository name on github. The name follows the rule:
        tf-[cloudprovider]-[vs|project]-resources
      </FormHelperText>
    </FormControl>
  );
};

export const GBReviewTerraformFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBReviewTerraform,
    name: 'GBReviewTerraform'
  })
);
