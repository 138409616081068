import React from 'react';

import Button from '@mui/material/Button';

import GitHubIcon from '@mui/icons-material/GitHub';

import { useStyles } from './styles';
import type { ViewSourceButtonProps } from './types';

export const ViewSourceButton = ({ viewUrl }: ViewSourceButtonProps) => {
    const S = useStyles();
    return (
      <Button
        className={S.buttonAction}
        variant="contained"
        color="primary"
        component="a"
        href={viewUrl}
        target="_blank"
      >
        <GitHubIcon className={S.iconsButton} />
        View Source
      </Button>
    );
  };