import { makeStyles } from '@mui/styles';

const spaceBetween = 'space-between';

export const useStyles = makeStyles((theme) => ({
  Container: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.mode === 'dark' ? '#00000033' : '#00000003',
    display: 'flex',
    borderRadius: '6px',
    flexDirection: 'column',
    width: '414px',
  },
  Content: {
    padding: '8px 24px 24px',
    display: 'flex',
    flexDirection: 'column',
  },
  MembersAndRoleContainer: {
    display: 'flex',
    justifyContent: spaceBetween,
    alignItems: 'center',
  },

  TeamContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px 24px',
    backgroundColor: theme.palette.mode === 'dark' ? '#0000001A' : '#00000005',
    borderRadius: '6px 6px 0px 0px',
    justifyContent: spaceBetween,
  },
  TeamHeader: {
    flexDirection: 'column',
  },
  TeamName: {
    fontSize: '16px',
    margin: '0px',
  },
  Description: {
    fontSize: '12px',
    margin: '0px',
  },
  DividerVertical: {
    maxHeight: '80%',
  },
  EscalationTitle: {
    fontSize: '14px',
    marginTop: '24px',
  },
  EscalationDescription: {
    fontSize: '12px',
    margin: '0px',
  },
  EscalationTime: {
    width: '100%',
    margin: '0px',
    display: 'flex',
    justifyContent: spaceBetween,
    alignItems: 'center',
  },
  StartDate: {
    fontSize: '11px',
  },
  DividerHorizontal: {
    maxWidth: '80%',
    flex: '1 1 auto',
  },
  EndDate: {
    fontSize: '11px',
  },
  ScaledNameContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '22px',
    borderRadius: '4px',
    backgroundColor: theme.palette.mode === 'dark' ? '#2E77D099' : '#9CC9FF',
    fontWeight: 700,
    fontSize: '11px',
  },
}));
