import React from 'react';

import { useStyles } from './styles';
import type { TypeEntityProps } from './types';

export const TypeEntity = ({ type }: TypeEntityProps) => {
    const S = useStyles();
    return (
      <div className={S.contDetails}>
        <p className={S.titleProperty}>TYPE</p>
        <p className={S.resultProperty}>{type}</p>
      </div>
    );
  };