import { useEffect } from 'react';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';

export function MermaidBackground() {
  const images = useShadowRootElements < HTMLImageElement > (['img']);

  useEffect(() => {
    images.forEach((img) => {
      if (img.alt === 'Kroki') {
        img.style.background = '#f8f8f8';
      }
    });
  }, [images]);

  return null;
}
