import React, { useState, useEffect } from 'react';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';
import { useApi, configApiRef, identityApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';

import axios from 'axios';
import { UseCustomFieldDomainFacade } from '../../../../facade/useCustomFieldDomainFacade';

interface Certificate {
  domain: string | undefined;
  dnsName: string | undefined;
  hostedZoneId: string | undefined;
  [key: string]: any;
}

interface ExcludeDNS {
  dns: string;
  type: 'contains' | 'equals';
}

export const GBTechGetDomains = ({
  onChange,
  schema: { title = 'Certificates ARN', description = 'Domains list from GB' },
  required,
  uiSchema,
  rawErrors,
  formData,
  formContext
}: FieldExtensionComponentProps<Certificate>) => {
  const options = 'ui:options';
  const allowNewValue = (uiSchema[options]?.allowNewValue as boolean) || false;
  const domain = UseCustomFieldDomainFacade.getDomain();
  const config = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const excludeList = uiSchema[options]?.excludeList as ExcludeDNS[] | undefined;

  const [selectedCertificate, setSelectedCertificate] = useState<Certificate>();
  const [listCertificates, setListCertificates] = useState<any[]>([]);
  const [environmentsAvailable, setEnvsAvailable] = useState<any>({});
  const [changedEnvSet, setChangedEnvSet] = useState<boolean>();
  const [loading, setLoad] = useState<boolean>(true);
  const [subDomain, setSubDomain] = useState<string>();
  const [certificate, setCertificate] = useState<Certificate>();
  const [error, setError] = useState<string>();
  const backendUrl: string = config.get('backend.baseUrl');

  const validateDNS = async (dns: any) => {
    const errors = [];
    for (const url of Object.values(dns)) {
      try {
        const { data } = await axios.get(`/api/proxy/checkDns/resolve?name=${url}`, {
          baseURL: backendUrl,
          headers: {
            'Content-Type': 'application/json'
          },
          withCredentials: true
        });

        if (data.Status !== 3) {
          errors.push(`DNS ${url} não está disponível`);
        }
      } catch (e) {
        errors.push(`DNS ${url} não está disponível`);
      }
    }

    if (errors.length > 0) {
      setError(errors.join('\n'));
      return false;
    }

    return true;
  };

  const arraysHaveSameValues = (firstArray: string[], secondArray: string[]) => {
    if (firstArray.length !== secondArray.length) {
      return false;
    }

    const sortedA = [...firstArray].sort((a, b) => a.localeCompare(b));
    const sortedB = [...secondArray].sort((a, b) => a.localeCompare(b));

    for (let i = 0; i < sortedA.length; i++) {
      if (sortedA[i] !== sortedB[i]) return false;
    }

    return true;
  };

  const getEnvironmentSet = async (environmentSetName: any) => {
    const request = {
      filter: {
        kind: 'EnvironmentSet',
        'metadata.name': environmentSetName
      }
    };

    const response = await catalogApi.getEntities(request);
    return response.items[0];
  };

  const populateCertificates = async () => {
    const environmentSetName = formContext.formData.catalogInfo?.environmentSetName;
    let environmentSet = formContext.formData.environmentSet;

    if (!environmentSet && environmentSetName) {
      environmentSet = await getEnvironmentSet(environmentSetName);
    } else if (!environmentSet) {
      throw new Error(
        'Este serviço está disponível apenas para aplicações que utilizam EnvironmentSet. Recomendamos que atualize seu componente.'
      );
    }
    const credentials = await identityApi.getCredentials();
    const { data } = await axios.get(
      `api/aws/${domain}/certificates/${environmentSet.metadata.name}`,
      {
        baseURL: backendUrl,
        headers: {
          Authorization: `Bearer ${credentials.token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      }
    );

    let filteredCertificates: any[] = data.certificates;

    if (excludeList && Array.isArray(excludeList) && excludeList.length > 0) {
      const equalsExclusions = excludeList.filter((e) => e.type === 'equals').map((e) => e.dns);
      const containsExclusions = excludeList.filter((e) => e.type === 'contains').map((e) => e.dns);
      filteredCertificates = data.certificates.filter((c: any) => {
        return (
          !equalsExclusions.includes(c.domain) &&
          !containsExclusions.some((exclusion) => c.domain.includes(exclusion))
        );
      });
    }

    setListCertificates(filteredCertificates);
    setLoad(false);
  };

  let showEnvsDns = uiSchema[options]?.showEnvsDns;

  if (typeof showEnvsDns === 'undefined') {
    showEnvsDns = true;
  }

  useEffect(() => {
    async function run() {
      let formattedDomain = '';

      if (formData && Object.keys(formData).length > 0) {
        if (formData.environments && formData.domain && formData.dnsName) {
          const formDataEnvs = Object.keys(formData.environments);
          const formContextEnvSetEnvs = formContext.formData.environmentSet.spec.environments.map(
            (e: any) => e.name
          );

          formattedDomain = formData.domain.replace('*.', '');
          setSubDomain(formData.dnsName.replace(`.${formattedDomain}`, ''));

          if (arraysHaveSameValues(formDataEnvs, formContextEnvSetEnvs)) {
            setChangedEnvSet(false);
            setEnvsAvailable(formData.environments);
            setCertificate(formData);
            setSelectedCertificate(formData);
            setEnvsAvailable(formData.environments);
          } else {
            setChangedEnvSet(true);
            setEnvsAvailable(undefined);
            setSelectedCertificate(undefined);
            setCertificate(undefined);
            onChange(undefined);
          }
        }
      }

      await populateCertificates();
    }
    run();
  }, []);

  const onSelect = async (___: any, selectedValue: Certificate) => {
    setSelectedCertificate(selectedValue);
    let formattedDomain = '';
    if (selectedValue && selectedValue.domain) {
      formattedDomain = selectedValue.domain.replace('*.', '');
      setCertificate(selectedValue);
      setEnvsAvailable(selectedValue.environments);
    } else {
      setCertificate(undefined);
    }

    if (error) {
      onChange(undefined);
      return;
    }

    if (
      typeof subDomain === 'string' &&
      subDomain !== 'undefined' &&
      selectedValue &&
      formattedDomain
    ) {
      const dns: any = {};

      if (environmentsAvailable) {
        Object.entries(environmentsAvailable).forEach(
          ([key, value]: [string, any]) =>
            (dns[key] = `https://${
              value.prefix
            }${subDomain}.${certificate?.domain?.replace('*.', '')}`)
        );
      }

      const validDNS = await validateDNS(dns);

      if (validDNS) {
        onChange({
          ...selectedValue,
          dnsName: `${subDomain}.${formattedDomain}`
        });
      } else {
        onChange(undefined);
      }
    } else {
      onChange(undefined);
    }
  };

  const onInput = async (e: any) => {
    const subDomainText = e.target?.value;
    setSubDomain(subDomainText);

    let _error: string | undefined = undefined;
    if (subDomainText.indexOf('.') > -1) {
      _error = 'Subdomain deve ter apenas um nível.';
    } else if (subDomainText.length < 3) {
      _error = 'Subdomain deve conter pelo menos 3 caracteres.';
    } else if (!subDomainText.match(/^[a-z0-9][a-z0-9-]+[a-z0-9]$/)) {
      _error =
        'Subdomain deve ser alfanumérico e pode conter hífens, mas não pode começar ou terminar com ele.';
    }

    if (certificate && !_error) {
      const dns: any = {};

      if (environmentsAvailable) {
        Object.entries(environmentsAvailable).forEach(
          ([key, value]: [string, any]) =>
            (dns[key] = `https://${
              value.prefix
            }${subDomainText}.${certificate?.domain?.replace('*.', '')}`)
        );
      }

      setError(undefined);
      const validDNS = await validateDNS(dns);
      if (validDNS) {
        onChange({
          domain: selectedCertificate?.domain,
          dnsName: `${subDomainText}.${certificate?.domain?.replace('*.', '')}`,
          hostedZoneId: selectedCertificate?.hostedZoneId,
          environments: environmentsAvailable
        });
      }
    } else {
      onChange(undefined);
      setError(_error);
    }
  };

  return (
    <FormControl>
      <TextField
        error={!!error}
        label="Subdomain"
        margin="normal"
        onChange={onInput}
        variant="outlined"
        inputProps={{ 'data-testid': 'input-getsubdomain' }}
        required={required}
        value={subDomain ?? ''}
        helperText={error}
      />

      <Autocomplete
        loading={loading}
        selectOnFocus
        clearOnBlur
        data-testid="autocomplete-getdomains"
        onChange={onSelect}
        autoSelect
        options={listCertificates}
        value={changedEnvSet ? certificate : formData}
        getOptionLabel={(option) => {
          return option.domain;
        }}
        freeSolo={allowNewValue}
        renderInput={(params) => (
          <TextField
            {...params}
            error={rawErrors?.length > 0 && !formData}
            label={title}
            margin="normal"
            helperText={description}
            variant="outlined"
            data-testid="autocomplete-getdomains-input"
            required={required}
          />
        )}
      />
      {showEnvsDns &&
        selectedCertificate !== undefined &&
        selectedCertificate !== null &&
        subDomain !== undefined &&
        typeof subDomain === 'string' &&
        subDomain !== 'undefined' &&
        certificate !== undefined && (
          <Typography display="block" variant="subtitle1" gutterBottom>
            Your Component URLs will be as follows:
            {environmentsAvailable &&
              Object.entries(environmentsAvailable)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([key, value]: [string, any]) => (
                  <Typography key={key}>
                    {key.toUpperCase()}:{' '}
                    {`https://${
                      value.prefix
                    }${subDomain}.${certificate?.domain?.replace('*.', '')}`}
                  </Typography>
                ))}
          </Typography>
        )}
    </FormControl>
  );
};

export const GBTechGetDomainFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechGetDomains,
    name: 'GbGetDomains'
  })
);
