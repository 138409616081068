import React, { useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';

import { addHours, formatDate } from 'date-fns';

import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { getOpsgenieScheduleMembers } from '../../../services/api';
import { OpsgenieTeam } from '../types';

import TeamContextMenu from './TeamContextMenu';
import { OverrideDialog } from '../OverrideDialog';
import { DeleteDialog } from '../DeleteDialog';
import { useStyles } from './styles';
import * as T from './types';

export function TeamCard({
  name,
  description,
  schedules,
  onEdit
}: OpsgenieTeam & T.TeamCardProps) {
  const S = useStyles();

  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  const [onCall, setOnCall] = useState<string>('Loading...');
  const [shift, setShift] = useState<any>({
    startDate: '',
    endDate: ''
  });
  const [overrideDialog, setOverrideDialog] = useState<T.ModalData>({
    open: false
  });
  const [deleteDialog, setDeleteDialog] = useState<T.ModalData>({
    open: false
  });

  const getScheduleMembers = async () => {
    const actualSchedule = schedules?.find((schedule) => schedule.enabled);

    if (!actualSchedule) {
      setOnCall('No schedule member found');
      return;
    }

    const startShift = new Date();
    startShift.setHours(actualSchedule?.templateConfig.nightShiftStartHour || 0, 0, 0, 0);
    const endShift = addHours(startShift, 12);
    setShift({
      startDate: formatDate(startShift, 'dd/MM HH:mm'),
      endDate: formatDate(endShift, 'dd/MM HH:mm')
    });

    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const credentials = await identityApi.getCredentials();

      const result = await getOpsgenieScheduleMembers(
        baseUrl,
        actualSchedule?.id,
        startShift,
        credentials.token
      );

      setOnCall(result[0]?.split('@')[0]);
    } catch (error) {
       
    }
  };

  const openOverrideDialog = () => {
    setOverrideDialog({ open: true });
  };

  const openDeleteDialog = () => {
    setDeleteDialog({ open: true });
  };

  useEffect(() => {
    getScheduleMembers();
  }, []);

  return (
    <>
      <div className={S.Container}>
        <header className={S.TeamContainer}>
          <div className={S.TeamHeader}>
            <h3 className={S.TeamName}>{name}</h3>
            <p className={S.Description}>{description || 'No description.'}</p>
          </div>
          <TeamContextMenu
            edit={onEdit}
            deleteTeam={openDeleteDialog}
            addOverride={openOverrideDialog}
          />
        </header>
        <main className={S.Content}>
          <p className={S.EscalationDescription}>On call</p>
          <div className={S.EscalationTime}>
            <p className={S.StartDate}>{shift.startDate}</p>
            <Divider className={S.DividerHorizontal} orientation="horizontal" variant="middle" />
            <p className={S.EndDate}>{shift.endDate}</p>
          </div>
          <div className={S.ScaledNameContainer}>
            <p>{onCall}</p>
          </div>
        </main>
      </div>

      <OverrideDialog
        handleClose={() => setOverrideDialog({ open: false })}
        open={overrideDialog.open}
        teamName={name}
        scheduleId={schedules?.find((schedule) => schedule.enabled)?.id || ''}
      />

      <DeleteDialog
        handleClose={() => setDeleteDialog({ open: false })}
        open={deleteDialog.open}
        teamName={name}
      />
    </>
  );
}
