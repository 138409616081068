import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  contAbout: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '20px',
    borderRadius: '6px',
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '0px 0px 10px 0px',
  },
  propertiesDetails: {
    marginBottom: '25px',
    display: 'flex',
    flexDirection: 'column',
  },
  propertiesDetailsLine: {
    display: 'flex',
    flexDirection: 'row',
  }
}));
