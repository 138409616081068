import axios from 'axios';
export const getChartVersion = async (baseUrl: string, chart: string, token: string) => {
  const { data } = await axios.get<any>(`${baseUrl}/api/proxy/chart-version/${chart}/latest`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    },
    withCredentials: true
  });
  return data;
};
