import axios from 'axios';
import { compare, coerce } from 'semver';

const functionsWrapper: any = {
  getRDSEngineVersions: async function (baseUrl: string, params: any, config: any) {
    try {
      const { engine } = params;
      const { data } = await axios.get(`/api/proxy/aws-info/rds/${engine}/versions`, {
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${config.token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });

      return getLatestThreeMajorVersions(data);
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  getGlueConnections: async function (baseUrl: string, params: any, config: any) {
    try {
      const { domain, env } = params;
      const response = await axios.get(`/api/proxy/aws-info/glue/${domain}/connections/${env}`, {
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${config.token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      if (response.status === 204) {
        return [];
      }
      return response.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  },
  getOrgsFromConfig: async function (_baseUrl: string, _params: any, config: any) {
    return JSON.parse(config.getString('github.allowedOrganizations')) as string[];
  },
  getOpsgenieTeams: async function (baseUrl: string, params: any, config: any) {
    try {
      const { domain } = params;
      const { data } = await axios.get(`api/opsgenie/teams/${domain}`, {
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${config.token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      const results = data.map((team: any) => ({
        value: team.name,
        text: team.name
      }));
      results.unshift({
        value: '',
        text: 'Não vincular time do opsgenie'
      });
      return results;
    } catch (e) {
      console.error(e);
      return [];
    }
  },

  getElasticacheVersions: async function (baseUrl: string, params: any, config: any) {
    try {
      const { engine } = params;
      const { data } = await axios.get(`/api/proxy/aws-info/elasticache/${engine}/versions`, {
        baseURL: baseUrl,
        headers: {
          Authorization: `Bearer ${config.token}`,
          'Content-Type': 'application/json'
        },
        withCredentials: true
      });
      return getLatestThreeMajorVersions(data);
    } catch (e) {
      console.error(e);
      return [];
    }
  }
};

function getLatestThreeMajorVersions(versions: string[]): string[] {
  const sortedVersions = versions.sort((a, b) => {
    const coearcedA = coerce(a);
    const coearcedB = coerce(b);
    if (!!coearcedA && !!coearcedB) {
      return compare(coearcedB?.raw, coearcedA?.raw);
    }
    return 0;
  });

  const uniqueMajorVersions = new Set<string>();
  const latestThreeMajorVersions: string[] = [];
  for (const version of sortedVersions) {
    const majorVersion = version.split('.')[0];
    if (!uniqueMajorVersions.has(majorVersion)) {
      uniqueMajorVersions.add(majorVersion);
      latestThreeMajorVersions.push(version);
    }
    if (latestThreeMajorVersions.length === 3) {
      break;
    }
  }

  return latestThreeMajorVersions;
}

export default async function call(
  baseUrl: string,
  functionName: string,
  params: any,
  config?: any
) {
  return await functionsWrapper[functionName](baseUrl, params, config);
}
