import React from 'react';

import Grid from '@mui/material/Grid';

import { EntityLayout } from '@backstage/plugin-catalog';
import {
  EntityOwnershipCard,
  EntityUserProfileCard,
} from '@backstage/plugin-org';

import { EntityWarningContent } from '../Contents/EntityWarning';
import EntityLayoutWrapper from '../../../components/EntityLayoutWrapper';

export const UserPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarningContent />
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard
            variant="gridItem"
            entityFilterKind={['Component', 'API', 'System']}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
