import {
  createTechDocsAddonExtension,
  TechDocsAddonLocations,
} from '@backstage/plugin-techdocs-react';
import { techdocsModuleAddonsContribPlugin } from '@backstage/plugin-techdocs-module-addons-contrib';
import { MermaidBackground } from './mermaidBackground';

export const MermaidBackgroundAddon = techdocsModuleAddonsContribPlugin.provide(
  createTechDocsAddonExtension({
    name: 'ExpandableNavigation',
    location: TechDocsAddonLocations.Content,
    component: MermaidBackground,
  }),
);
