import React, { useEffect, useState } from 'react';

import { githubAuthApiRef, useApi } from '@backstage/core-plugin-api';

import OpenInNew from '@mui/icons-material/OpenInNew';
import  RefreshOutlined from '@mui/icons-material/RefreshOutlined';

import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import type { RequestHistoryProps, RequestHistory } from './types';
import { useStyles } from './styles';
import { getRequestHistory } from '../../../services/api';

export function RequestsHistory({ orgName, refreshHistory }: Readonly<RequestHistoryProps>) {
  const S = useStyles();

  const githubAuth = useApi(githubAuthApiRef);

  const [requests, setRequests] = useState<RequestHistory[]>([]);

  const getRequests = async () => {
    try {
      const githubToken = await githubAuth.getAccessToken([
        'repo',
        'repo:status',
        'repo_deployment',
        'public_repo',
        'repo:invite',
        'security_events'
      ]);

      const requestsHistory = await getRequestHistory(orgName, githubToken);

      setRequests(requestsHistory.workflow_runs);
      refreshHistory();
    } catch (error) {
      console.error(error);
    }
  };

  const getStatusClassName = (status: string, conclusion: string) => {
    const statusObj: Record<string, string[]> = {
      success: ['success', 'completed'],
      info: ['neutral', 'in_progress', 'queued', 'requested', 'waiting', 'pending'],
      warning: ['action_required', 'skipped', 'stale', 'timed_out'],
      error: ['failure', 'cancelled']
    };

    if (status === 'completed' && statusObj.error.includes(conclusion)) {
      return 'error';
    }

    for (const key in statusObj) {
      if (statusObj[key].includes(status)) {
        return key;
      }
    }

    return '';
  };

  useEffect(() => {
    getRequests();

    const cleanInterval = setInterval(() => getRequests(), 1000 * 60 * 2);

    return () => clearInterval(cleanInterval);
  }, []);

  return (
    <div className={S.Container}>
      <h2>
        Requests History
        <IconButton onClick={() => getRequests()} data-testid="refresh-button">
          <RefreshOutlined />
        </IconButton>
      </h2>

      <div className={S.RequestHistoryContainer}>
        {requests.length === 0 && (
          <List>
            <ListItem>
              <ListItemText primary="No request history for now" />
            </ListItem>
          </List>
        )}
        {requests.map((request) => (
          <List dense key={request.display_title}>
            <ListItem>
              <ListItemIcon>
                <IconButton href={request.html_url} target="_blank">
                  <OpenInNew />
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primary={request.display_title}
                secondary={
                  <span
                    className={`${S.RequestStatus}
                      ${getStatusClassName(request.status, request.conclusion)}`}
                  >
                    {request.conclusion || request.status}
                  </span>
                }
              />
            </ListItem>
          </List>
        ))}
      </div>
    </div>
  );
}
