import React from 'react';
import Button from '@mui/material/Button';

import { useStyles } from './styles';
import * as T from './types';

export default function PlaceholderComponent({
  srcImage,
  altImage,
  title,
  subtitle,
  buttonLink,
  buttonText,
  onClick,
}: T.PlaceholderComponentProps) {
  const S = useStyles();

  return (
    <div className={S.container}>
      <div className={S.textContainer}>
        <h2>{title}</h2>
        {subtitle && <p>{subtitle}</p>}
        {buttonLink && buttonText && (
          <Button variant="contained" color="primary" onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </div>
      <img className={S.image} src={srcImage} alt={altImage} />
    </div>
  );
}
