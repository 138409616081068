import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '40px',
    width: '100%',
  },
  RequestHistoryContainer: {
    maxHeight: 450,
    backgroundColor: theme.palette.mode === 'dark' ? '#00000033' : '#00000003',
    overflowY: 'auto',
    borderRadius: 6,
    '&::-webkit-scrollbar': {
      width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'rgba(255, 255, 255, 0.40)',
      borderRadius: 6,
    },
  },
  RequestStatus: {
    margin: 0,
    '&.success': {
      color: theme.palette.success.main,
    },
    '&.info': {
      color: theme.palette.info.main,
    },
    '&.warning': {
      color: theme.palette.warning.main,
    },
    '&.error': {
      color: theme.palette.error.main,
    },
  },
}));
