import React from 'react';

import { Card, CardContent, CardHeader, Divider, Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

import { Entity } from '@backstage/catalog-model';

import { useStyles } from './styles';

export const EndpointsCard = ({ ...entity }: Entity) => {
  const S = useStyles();
  const links = entity.metadata.links;

  const getDisplayText = (link: { title?: string; url: string }) => {
    const displayText = link.title || link.url.split('https://')[1].split('/')[0] + '...';
    return (
      <Link key={`${link.url}${link.title}`} href={link.url} target="_blank" className={S.Link}>
        <LaunchIcon className={S.Icon} />
        {displayText}
      </Link>
    );
  };

  return links && links.length > 0 ? (
    <Card data-testid="endpoints-card">
      <CardHeader
        title="Endpoints"
        subheader="Access the available endpoints"
        classes={{ subheader: S.subheader }}
      />
      <Divider />
      <CardContent className={S.CardContent}>
        <div className={S.CardLinks}>{links.map((link) => getDisplayText(link))}</div>
      </CardContent>
    </Card>
  ) : null;
};
