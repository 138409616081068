import React, { useState, useEffect } from 'react';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps
} from '@backstage/plugin-scaffolder-react';
import { useApi, configApiRef, identityApiRef } from '@backstage/core-plugin-api';

import { catalogPlugin } from '@backstage/plugin-catalog';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import functionCaller from './functions';
import { useStyles } from './styles';
import { UseCustomFieldDomainFacade } from '../../../../facade/useCustomFieldDomainFacade';

interface Function {
  name: string;
  params: object;
}

export interface TextValue {
  text: string;
  value: any | string;
}

function isTextValue(object: any): object is TextValue {
  return 'value' in object;
}

export const GBTechList = ({
  onChange,
  schema,
  required,
  uiSchema,
  rawErrors,
  formData
}: FieldExtensionComponentProps<string>) => {
  const S = useStyles();
  const domain = UseCustomFieldDomainFacade.getDomain();
  const uiOptions = 'ui:options';
  const func = uiSchema[uiOptions]?.function as Function;
  const valueProperty = (uiSchema[uiOptions]?.key as string) || undefined;
  const textProperty = (uiSchema[uiOptions]?.textProperty as string) || undefined;
  const multiple = (uiSchema[uiOptions]?.multiple as boolean) || false;
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const baseUrl: string = config.get('backend.baseUrl') || '';
  const [list, setList] = useState<TextValue[]>([{ value: undefined, text: 'Loading...' }]);
  schema.title = schema.title || 'List';

  useEffect(() => {
    async function getOptions() {
      const params = {
        domain,
        ...func.params
      };
      const credentials = await identityApi.getCredentials();
      const options = await functionCaller(baseUrl, func.name, params, {
        ...config,
        token: credentials?.token
      });
      let formattedOptions: TextValue[] = [];
      if (valueProperty && textProperty) {
        formattedOptions = options.map((o: any) => {
          return { value: o[valueProperty], text: o[textProperty] };
        });
      } else if (!valueProperty && textProperty) {
        formattedOptions = options.map((o: any) => {
          return { value: o, text: o[textProperty] };
        });
      } else if (options.every((opt: any) => typeof opt !== 'string' && isTextValue(opt))) {
        formattedOptions = options;
      } else {
        formattedOptions = options.map((o: any) => {
          return { value: o, text: o };
        });
      }
      setList(formattedOptions);
      if (formData) {
        onChange(formData);
      }
    }
    getOptions();
  }, []);

  return (
    <FormControl fullWidth>
      <InputLabel id="label-gbtech-list">{schema.title}</InputLabel>
      <Select
        value={formData ?? ''}
        onChange={(selected) => onChange(selected?.target?.value as string)}
        labelId="label-gbtech-list"
        data-testid="gbtech-list"
        id={schema.title}
        label={schema.title}
        multiple={multiple}
        required={required}
        error={rawErrors?.length > 0 && !formData}
      >
        {list &&
          list.length > 0 &&
          list.map((opt) => (
            <MenuItem className={S.Container} value={opt.value} key={opt.text}>
              {opt.text}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export const GBTechListFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechList,
    name: 'GbList'
  })
);

export const GBTechListAutoServiceExtension = catalogPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechList,
    name: 'GbList'
  })
);
