import React from 'react';

import { getGithubRepo, getRepositoryApps } from '@grupoboticario/plugin-argo-cd-frontend';

import { Entity } from '@backstage/catalog-model';

import { RenderStatus } from '../RenderStatus';

export const ArgoStatus = (entity: Entity) => {
  const location = entity.metadata?.annotations?.['backstage.io/managed-by-location'];
  const argo = entity.metadata?.annotations?.['argocd/app-selector'];
  const domainEntity = entity.spec?.domain;
  const entityKind = entity.kind;

  if (!location || !argo || !domainEntity || entityKind !== 'Component') {
    return null;
  }

  const { repo } = getGithubRepo(location);
  const appSelector = argo;

  const { value } = getRepositoryApps(repo, appSelector, domainEntity as string);

  if (!value?.items?.length) {
    return null;
  }

  const isSync = !value?.items?.some((row: any) => row.status.sync.status !== 'Synced');

  return <RenderStatus valueText={isSync ? 'synced' : 'out of sync'} coverageOrSync={isSync} />;
};
