import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  MembersList: {
    overflowY: 'auto',
    padding: 4,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: `${theme.palette.text.secondary}`,
      opacity: 0.2,
      borderRadius: '6px',
    },
    maxHeight: '152px',
  },
  Member: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.28)',
    padding: '6px 6px 6px 18px',
    marginBottom: theme.spacing(1),
    height: 52,
  },
  Email: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 400,
  },
  members: {
    margin: 0,
  },
}));
