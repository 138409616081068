import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import axios from 'axios';
import { Entity } from '@backstage/catalog-model';

export class AutoServiceApiClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;

  constructor(options: { discoveryApi: DiscoveryApi; identityApi: IdentityApi }) {
    this.discoveryApi = options.discoveryApi;
    this.identityApi = options.identityApi;
  }

  private async getBaseUrl() {
    return await this.discoveryApi.getBaseUrl('');
  }

  async getAmbientes(entity: Entity) {
    const proxyUrl = await this.getBaseUrl();
    const params: { [key: string]: string | undefined } = {
      selector: entity?.metadata.annotations?.['argocd/app-selector']
    };
    const query = Object.keys(params)
      .filter((key) => params[key] !== undefined)
      .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(params[k] as string)}`)
      .join('&');
    const auth = await this.identityApi.getCredentials();
    return axios.get(`${proxyUrl}argocd/${entity.spec?.domain}?${query}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth?.token}`
      }
    });
  }

  async callService(formData: any) {
    const credentials = await this.identityApi.getCredentials();
    const url = await this.getBaseUrl();
    const response = await axios.post(
      `${url}autoservice`,
      { formData },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials?.token}`
        },
        withCredentials: true
      }
    );
    return response;
  }
}
