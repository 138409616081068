import { makeStyles } from '@mui/styles';
import { sidebarConfig } from '@backstage/core-components';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
  },
  links: {
    textDecoration: 'underline',
  },
  text: {
    margin: '0px',
  },
  alert: {
    backgroundColor: `color-mix(in srgb, ${theme.palette.warning.dark} 30%, ${theme.palette.background.default} 70%)`,
    position: 'relative',
    zIndex: 100,
  },
}));
