import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  Container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  RuleCard: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    background: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#00000008',
    borderRadius: '4px',
    padding: theme.spacing(2, 2),
  },
  Field: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  Button: {
    padding: '5px',
    margin: '0px',
    borderRadius: '4px',
    border: `1px solid ${
      theme.palette.mode === 'dark' ? '#FFFFFF33' : '#00000010'
    }`,
    background: theme.palette.mode === 'dark' ? '#FFFFFF1A' : '#00000008',
    minWidth: '18px',
    minHeight: '18px',
  },
  Buttons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  },
  AddRules: {
    fontSize: '12px',
    fontWeight: 400,
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),

    '& > span > span': {
      marginRight: theme.spacing(0.5),
    },
  },
}));
