import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';

import { stringifyEntityRef } from '@backstage/catalog-model';
import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import { MarkdownContent } from '@backstage/core-components';

import { getReadmeData } from '../../../services/api';
import { useStyles } from './styles';

export default function Readme() {
  const S = useStyles();

  const { entity } = useEntity();
  const discoveryApi = useApi(discoveryApiRef);
  const identityApi = useApi(identityApiRef);

  const [content, setContent] = useState<string>(
    'No `README.md` file found in the project!',
  );

  const fetchData = async () => {
    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const { token } = await identityApi.getCredentials();

      const { data } = await getReadmeData(
        token,
        baseUrl,
        stringifyEntityRef(entity),
      );

      if (data) {
        setContent(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper className={S.Container}>
      <MarkdownContent content={content} />
    </Paper>
  );
}

export const ReadmePage = <Readme />;
