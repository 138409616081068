import React from 'react';

import { useStyles } from './styles';
import type { ArgoCdUrlProps } from './types';

export const ArgoCdUrl = ({ url }: ArgoCdUrlProps) => {
  const S = useStyles();
  return (
    <div className={S.itemDetails}>
      <p className={S.titleProperty}>ARGOCD URL</p>
      <p className={S.resultProperty}>
        <a
          href={`https://${url}`}
          target="_blank"
          rel="noopener noreferrer"
          className={S.link}
        >
          {url}
        </a>
      </p>
    </div>
  );
};
