import React from 'react';

import LightIcon from '@mui/icons-material/LightMode';
import Brightness2Icon from '@mui/icons-material/Brightness2';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';

import { UnifiedThemeProvider } from '@backstage/theme';
import { themes as builtInThemes } from '@backstage/theme';

import { themes } from './customThemes';

export const getTheme = (baseUrl: string) => {
  if (baseUrl.includes('dalquimia')) {
    return { light: themes.devThemeLight, dark: themes.devThemeDark };
  } else if (baseUrl.includes('halquimia')) {
    return { light: themes.hmlThemeLight, dark: themes.hmlThemeDark };
  } else if (baseUrl.includes('play')) {
    return { light: themes.playThemeLight, dark: themes.playThemeDark };
  } else {
    // Backstage default themes
    return { light: builtInThemes.light, dark: builtInThemes.dark };
  }
};

export const getDefaultTheme = (mode: 'dark' | 'light') => {
  return createTheme({ ...builtInThemes[mode].getTheme('v5') } as ThemeOptions);
};

export const createThemeConfig = (theme: any) => {
  return theme
    ? {
        themes: [
          {
            id: 'light-theme',
            title: 'Light Theme',
            variant: 'light',
            icon: <LightIcon />,
            Provider: ({ children }: any) => (
              <ThemeProvider theme={getDefaultTheme('light')}>
                <UnifiedThemeProvider theme={theme.light} children={children} />
              </ThemeProvider>
            ),
          },
          {
            id: 'dark-theme',
            title: 'Dark Theme',
            variant: 'dark' ,
            icon: <Brightness2Icon />,
            Provider: ({ children }: any) => (
              <ThemeProvider theme={getDefaultTheme('dark')}>
                <UnifiedThemeProvider theme={theme.dark} children={children} />
              </ThemeProvider>
            ),
          },
        ],
      }
    : {};
};
