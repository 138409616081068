import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  cont: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleCard: {
    margin: '0px 0px 20px 0px',
    fontSize: '24px',
  },
  toolkit: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    textAlign: 'center',
    maxHeight: '24vh',
    overflowY: 'auto',
    padding: '5px',
  },
  tool: {
    margin: '0px 10px 10px 0px',
    padding: '0px',
  },
  linkList: {
    margin: '5px',
    padding: '8px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    width: '100px',
    height: '100px',
    alignItems: 'center',
    fontSize: '5px',
  },
  icon: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px',
  },
  iconSize: {
    fontSize: '30px',
    margin: '0px',
    color: theme.palette.background.paper,
  },
  value: {
    fontSize: '8px',
  },
}));
