import React, { useContext, useState } from 'react';

import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';

import { addMinutes } from 'date-fns';
import { ptBR } from 'date-fns/locale/pt-BR';

import {
  discoveryApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';

import { OpsGenieContext } from '..';
import { UserAutocomplete } from '../UserAutocomplete';
import { addScheduleOverride } from '../../../services/opsgenie';
import { Member } from '../types';
import * as T from './types';
import { useStyles } from './styles';

export function OverrideDialog({
  handleClose,
  open,
  teamName,
  scheduleId,
}: T.OverrideModalProps) {
  const S = useStyles();

  const identityApi = useApi(identityApiRef);
  const discoveryApi = useApi(discoveryApiRef);
  const isAdmin = useContext(OpsGenieContext).isAdmin;

  const [formData, setFormData] = useState<T.OverrideFormData>({
    startDateTime: null,
    endDateTime: null,
    member: undefined,
    teamName: teamName,
    isFilled: false,
  });
  const [result, setResult] = useState({
    open: false,
    error: false,
    message: '',
  });

  const closeDialog = () => {
    setFormData({
      startDateTime: null,
      endDateTime: null,
      member: undefined,
      teamName: teamName,
      isFilled: false,
    });
    handleClose();
  };

  const handleSanckBarClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setResult({ ...result, open: false });
  };

  const handleUserAutocompleteChange = (value: Member) => {
    setFormData({
      startDateTime: formData.startDateTime,
      endDateTime: formData.endDateTime,
      member: value,
      teamName: teamName,
      isFilled:
        teamName !== undefined &&
        value !== undefined &&
        formData.startDateTime !== undefined &&
        formData.endDateTime !== undefined,
    });
  };

  const submitOverride = async () => {
    setResult({ ...result, open: false });

    if (!formData.isFilled) {
      setResult({
        error: true,
        message: 'Por favor preencha todos os campos',
        open: false,
      });
      return;
    }

    try {
      const baseUrl = await discoveryApi.getBaseUrl('');
      const credentials = await identityApi.getCredentials();

      await addScheduleOverride(
        baseUrl,
        scheduleId,
        formData,
        credentials.token,
      );
      closeDialog();
      setResult({
        open: true,
        error: false,
        message: 'Substituição adicionada com sucesso.',
      });
    } catch (error) {
      console.error(error);
      setResult({
        error: true,
        message: 'Erro ao adicionar substituição de agendamento',
        open: false,
      });
    }
  };
  return (
    <>
      <Dialog onClose={closeDialog} open={open} maxWidth="sm" fullWidth>
        <div className={S.Container}>
          <header>
            <h2 className={S.Title}>{teamName}</h2>
            <p className={S.ScheduleOverrideTitle}>Schedule Override</p>
            <CloseIcon
              data-testid="close-icon"
              onClick={closeDialog}
              className={S.CloseIcon}
            />
          </header>
          <form className={S.Content}>
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <div>
                <div className={S.Dates}>
                  <DateTimePicker
                    label="Start"
                    name="startDateTime"
                    value={formData.startDateTime}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        startDateTime: e,
                      });
                    }}
                    disablePast
                  />
                  <DateTimePicker
                    label="End"
                    name="endDateTime"
                    value={formData.endDateTime}
                    onChange={e => {
                      setFormData({
                        ...formData,
                        endDateTime: e,
                      });
                    }}
                    minDateTime={addMinutes(
                      formData.startDateTime || new Date(),
                      5,
                    )}
                  />
                </div>
                <UserAutocomplete
                  label="Member"
                  placeholder="Search team member by email"
                  onChange={value => handleUserAutocompleteChange(value)}
                  value={formData.member}
                />
              </div>
            </LocalizationProvider>
          </form>
          {isAdmin && (
            <footer className={S.Footer}>
              {
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitOverride}
                  disabled={!formData?.isFilled}
                >
                  Confirm
                </Button>
              }
              {
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
              }
            </footer>
          )}
        </div>
      </Dialog>
      <Snackbar
        open={result.open}
        autoHideDuration={6000}
        onClose={handleSanckBarClose}
      >
        <Alert
          severity={result.error ? 'error' : 'success'}
          variant="filled"
          sx={{ width: '100%' }}
          onClose={handleSanckBarClose}
        >
          {result.message}
        </Alert>
      </Snackbar>
    </>
  );
}
