import React, { useState } from 'react';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

import { UserAutocomplete } from '../../UserAutocomplete';
import { EscalationRule } from '../types';
import { useStyles } from './style';
import * as T from './types';

export function EditState({
  onChange,
  onCancel,
  escalationRule,
}: T.EditStateProps) {
  const S = useStyles();

  const [rule, setRule] = useState<EscalationRule>(escalationRule);

  const alerts = ['if-not-acked', 'if-not-closed'];

  const handleSaveButton = () => {
    onChange({ ...rule, variant: 'view' });
  };

  const checkIfRuleIsFilled = () => {
    if (rule.condition && rule.recipient.type && rule.recipient.email) {
      return false;
    }

    return true;
  };

  if (!rule) {
    return null;
  }

  return (
    <div className={S.EditContainer}>
      <p className={S.Column1}>
        <b>Alert condition:</b>
      </p>
      <div className={S.Column2}>
        <Select
          value={rule.condition}
          onChange={e =>
            e.target.value &&
            setRule({ ...rule, condition: e.target.value as string })
          }
        >
          {alerts.map(item => (
            <MenuItem key={item} value={item.toLowerCase()} className={S.Item}>
              {item}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={rule.recipient.type}
          onChange={e =>
            e.target.value &&
            setRule({
              ...rule,
              recipient: { ...rule.recipient, type: e.target.value as string },
            })
          }
        >
          <MenuItem value="user">Notify user</MenuItem>
        </Select>
      </div>
      <div className={S.Column3}>
        <div className={S.MinutesContainer}>
          <input
            placeholder="0"
            value={rule.delay.timeAmount}
            onChange={e =>
              setRule({
                ...rule,
                delay: {
                  timeAmount: e.target.value.replace(
                    /\D/,
                    '',
                  ) as unknown as number,
                  timeUnit: 'minutes',
                },
              })
            }
            className={S.Minutes}
            maxLength={3}
          />
          <p className={S.MinutesLabel}>minutes after creation</p>
        </div>
        <UserAutocomplete
          label="Search for user"
          size="small"
          clearValueAfterSelect={false}
          className={S.SearchUser}
          onChange={value =>
            value &&
            setRule({
              ...rule,
              recipient: { type: 'user', id: value.id, email: value.email },
            })
          }
          value={rule.recipient}
          displayInfo={false}
        />
        <div className={S.ButtonsWrapper}>
          <Button
            variant="contained"
            size="small"
            className={S.Button}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={S.Button}
            disabled={checkIfRuleIsFilled()}
            onClick={handleSaveButton}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
}
