import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  Card: {
    width: 250,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? `${theme.palette.background.paper}15`
        : `${theme.palette.background.paper}30`,
  },
  Divider: {
    marginTop: theme.spacing(2),
  },
  Title: {
    textTransform: 'capitalize',
    margin: 0,
  },
  PlatformLogo: {
    width: 40,
    margin: 'auto 0',
  },
  AvatarWrapper: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));
