import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddCardOutlined from '@mui/icons-material/AddCardOutlined';
import MoreVert from '@mui/icons-material/MoreVert';
import DeleteForeverOutlined from '@mui/icons-material/DeleteForeverOutlined';
import RuleOutlined from '@mui/icons-material/RuleOutlined';
import EditCalendarOutlined from '@mui/icons-material/EditCalendarOutlined';
import PeopleOutlineOutlined from '@mui/icons-material/PeopleOutlineOutlined';

import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useStyles } from './styles';
import * as T from './types';

export default function TeamContextMenu({
  edit,
  addOverride,
  deleteTeam,
}: T.TeamContextMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const S = useStyles();

  return (
    <div className={S.ContextMenu}>
      <IconButton
        aria-label="more"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <div className={S.MenuWrapper}>
          <MenuItem
            onClick={() => {
              edit(1);
              handleClose();
            }}
          >
            <ListItemIcon>
              <PeopleOutlineOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Members</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              edit(3);
              handleClose();
            }}
          >
            <ListItemIcon>
              <RuleOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit Policies</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              edit(2);
              handleClose();
            }}
          >
            <ListItemIcon>
              <EditCalendarOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit schedules</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              addOverride();
              handleClose();
            }}
          >
            <ListItemIcon>
              <AddCardOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Create Override</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem
            className={S.DeleteTeamMenuItem}
            onClick={() => {
              deleteTeam();
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteForeverOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Delete team</ListItemText>
          </MenuItem>
        </div>
      </Menu>
    </div>
  );
}
