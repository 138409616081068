import { createWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';

type State = {
  domain: string;
};
type Actions = {
  setDomain: (domain: string) => void;
};
export const useCustomDomainFieldDomainStore = createWithEqualityFn<State & Actions>(
  (set) => ({
    domain: '',
    setDomain: (domain: string) => set({ domain })
  }),
  shallow
);
