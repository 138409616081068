import React, { useEffect, useState } from 'react';

import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import {
  createScaffolderFieldExtension,
  FieldExtensionComponentProps,
} from '@backstage/plugin-scaffolder-react';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { useStyles } from './styles';

export const GBTechProxyBasePath = ({
  schema,
  required,
  formContext,
  onChange,
}: FieldExtensionComponentProps<any>) => {
  schema.title = schema.title || 'Proxy Base Path';
  schema.description = schema.description || 'The proxy base path.';

  const [basePathPrefix, setBasePathPrefix] = useState<string>('');
  const [basePath, setBasePath] = useState<string>('');

  const S = useStyles();

  useEffect(() => {
    if (formContext.formData) {
      const { component, proxyType } = formContext.formData;
      if (
        typeof component?.name === 'string' &&
        typeof component?.preffix === 'string' &&
        typeof proxyType === 'string'
      ) {
        const proxyPrefix = component.preffix.match(/^px-(.*)/);
        const domain = proxyPrefix ? proxyPrefix[1].toLowerCase() : '';
        const proxyName = component.name.replace(domain, '') || '';
        const proxyVersion = proxyName.split('-').slice(-1)[0];
        const prefix = `/${proxyType}/${proxyVersion}/${domain}`;
        setBasePathPrefix(prefix);
      }
    }
  }, [formContext.formData?.proxyType, formContext.formData?.component]);

  useEffect(() => {
    onChange(basePathPrefix.toLowerCase() + basePath.toLowerCase());
  }, [basePath, basePathPrefix]);

  return (
    <FormControl>
      <TextField
        id="gbtech-proxy-basepath-input"
        data-testid="gbtech-proxy-basepath-input"
        label={schema.title}
        onChange={event => setBasePath(event.target.value)}
        margin="normal"
        variant="standard"
        helperText={schema.description}
        required={required}
        value={basePath}
        InputProps={{
          startAdornment: basePathPrefix ? (
            <InputAdornment className={S.prefixText} position="start">
              {basePathPrefix}
            </InputAdornment>
          ) : null,
        }}
      />
    </FormControl>
  );
};

export const GBTechProxyBasePathFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: GBTechProxyBasePath,
    name: 'GBTechProxyBasePath',
  }),
);
