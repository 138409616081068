import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef
} from '@backstage/core-plugin-api';
import { TechRadarClient } from './lib/TechRadarClient';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import {
  CatalogImportApi,
  catalogImportApiRef,
  CatalogImportClient
} from '@backstage/plugin-catalog-import';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi)
  }),
  ScmAuth.createDefaultApiFactory(),

  createApiFactory(techRadarApiRef, new TechRadarClient()),

  createApiFactory({
    api: catalogImportApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      scmAuthApi: scmAuthApiRef,
      fetchApi: fetchApiRef,
      scmIntegrationsApi: scmIntegrationsApiRef,
      catalogApi: catalogApiRef,
      configApi: configApiRef
    },
    factory: ({
      discoveryApi,
      scmAuthApi,
      fetchApi,
      scmIntegrationsApi,
      catalogApi,
      configApi
    }) => {
      const catalogImportClient = new CatalogImportClient({
        discoveryApi,
        scmAuthApi,
        fetchApi,
        scmIntegrationsApi,
        catalogApi,
        configApi
      }) as CatalogImportApi;
      catalogImportClient.preparePullRequest = undefined;
      return catalogImportClient;
    }
  })
];
