import React from 'react';

import Link from '@mui/material/Link';

import { useStyles } from './styles';
import * as T from './types';

export function ErrorStep({ messsage }: T.ErrorStepProps) {
  const S = useStyles();
  return (
    <p className={S.Text}>
      Something went wrong. Please try again.
      <br />
      <br />
      <b>Error:</b> {messsage}
      <br />
      <br />
      If you have any doubts, feel free to reach us on the channel{' '}
      <Link
        href="https://join.slack.com/share/enQtNjQ5MDI4MzMxNDkzMi02MGU1MGJkZWZiZjFjZWE4MjVkZjU2NzI1ODVlNmU5OGU1MTdlZTczY2YzMGE1ZTFkODQyNGZkMjliNTE3M2Zh"
        color="primary"
      >
        #ajuda-opsgenie
      </Link>
    </p>
  );
}
