import React from 'react';

import { useStyles } from './styles';

import { InfoCard } from '@backstage/core-components';

import Link from '@mui/material/Link';

import LaunchIcon from '@mui/icons-material/Launch';

import { RenderModal } from '../../RenderModal';
import type { ContributorSquadsProps }  from './types';

export const ContributorSquads = ({ squads, namespace }: ContributorSquadsProps) => {
  const S = useStyles();
  const removeNamespaceFromValue = (value: string) => value.replace(`${namespace}/`, '');

  if (squads.length === 0){
     return null
  }

  return (
    <div className={S.contDetails}>
      <p className={S.titleProperty}>CONTRIBUTOR SQUADS</p>
        <RenderModal titleLink="show squads">
          <InfoCard title="Contributor Squads">
            <div className={S.listSquads}>
              {Array.isArray(squads) && squads.map((squad, index) => (
                <Link key={index} href={`/catalog/${namespace}/system/${removeNamespaceFromValue(squad)}`} target="_blank" className={S.resultProperty}>
                  {squad}
                  <LaunchIcon className={S.icon} />
                </Link>
              ))}
            </div>
          </InfoCard>
        </RenderModal>
    </div>
  );
};
